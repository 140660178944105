<template>
  <div>
    <v-app-bar app color="#ff0000" flat dark style="z-index: 100">
      <v-spacer />
      <img :src="`${publicPath}img/logo_scg.svg`" style="height: 80%" />
      <v-spacer />
    </v-app-bar>

    <v-content>
      <div style="height: 10px; background: #ff0000">
        <div
          style="
            height: 15px;
            background: white;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
          "
        ></div>
      </div>
      <v-container>
        <template v-if="view == 'termsandcons'">
          <terms-and-cons></terms-and-cons>
          <div class="text-center mt-4">
            <v-btn class="ma-3" @click="onClose">Cancel</v-btn>
            <v-btn
              class="ma-3"
              @click="view = objective == 'event' ? 'interest' : 'createpdf'"
              color="primary"
              >Accept</v-btn
            >
          </div>
        </template>
        <template v-else-if="view == 'interest'">
          <v-layout align-center justify-center style="height: 80vh">
            <v-flex>
              <div class="text-center" style="max-width: 300px; margin: auto">
                <p class="primary--text">ความสนใจของคุณคือ</p>

                <v-btn
                  @click="
                    interest = 'สมัครงาน';
                    view = 'register';
                  "
                  block
                  >สมัครงาน/Job Apply</v-btn
                >

                <div class="mt-5">
                  <v-btn
                    @click="
                      interest = 'ฝึกงาน';
                      view = 'register';
                    "
                    block
                    >สมัครฝึกงาน/Internship Apply</v-btn
                  >
                </div>
              </div>
            </v-flex>
          </v-layout>
        </template>
        <template v-else-if="view == 'createpdf'">
          <div style="padding-bottom: 54px">
            <h3 class="headline text-center">เลือกธีมที่ต้องการ</h3>

            <v-row justify="center" align="center">
              <template v-for="index in 4">
                <v-col
                  cols="6"
                  sm="4"
                  md="3"
                  :key="'pdf_' + index"
                  @click="selectedPdfTemplate = index"
                  style="cursor: pointer"
                >
                  <div
                    style="
                      overflow: hidden;
                      position: relative;
                      background: #eee;
                      border-radius: 20px;
                    "
                    :style="{
                      border:
                        selectedPdfTemplate == index
                          ? '2px solid red'
                          : '2px solid #eee',
                    }"
                  >
                    <img
                      :src="`${publicPath}img/pdf/pdf${index}.jpg`"
                      style="width: 100%; display: block"
                    />
                    <div
                      style="
                        position: absolute;
                        right: 0;
                        top: 0;
                        padding: 5px;
                        border-bottom-left-radius: 20px;
                      "
                      class="primary"
                      v-if="selectedPdfTemplate == index"
                    >
                      <v-icon color="white">mdi-check</v-icon>
                    </div>
                  </div>
                </v-col>
              </template>
            </v-row>
          </div>
          <v-btn
            class
            block
            tile
            x-large
            color="primary"
            style="position: fixed; bottom: 0; right: 0; left: 0"
            :disabled="!selectedPdfTemplate"
            :loading="loadingProfile"
            @click="onGeneratePdf()"
            >สร้าง resume</v-btn
          >
        </template>
        <template v-else-if="view == 'register'">
          <stepper v-model="step" :type="interest"></stepper>
          <ValidationObserver
            ref="observer"
            v-slot="{}"
            tag="form"
            autocomplete="on"
          >
            <template v-if="step == 1">
              <div class="text-center mb-5">
                <div
                  style="
                    position: relative;
                    width: 109px;
                    height: 109px;
                    margin: auto;
                  "
                >
                  <upload-profile
                    name="profileimage"
                    v-model="info.profileimage"
                    :height="109"
                  ></upload-profile>
                </div>
                <div class="caption mt-2">
                  Click to upload your resume (PDF format)
                </div>
              </div>
              <p>Please complete your information</p>

              <v-row>
                <v-col cols="12" sm="4" md="2">
                  <form-label isRequired>Title / คำนำหน้าชื่อ</form-label>
                  <ValidationProvider
                    key="title"
                    :name="'Title / คำนำหน้าชื่อ'"
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-select
                      :items="titleOptions"
                      v-model="info.title"
                      label="Title / คำนำหน้าชื่อ"
                      solo
                      :error-messages="errors"
                      :success="valid"
                      :hide-details="errors.length == 0"
                    ></v-select>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" sm="4" md="5">
                  <form-label isRequired>First Name (English)</form-label>
                  <ValidationProvider
                    :name="'First Name'"
                    :rules="{ required: true, regex: /^[aA-zZ ]+$/ }"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      label="First Name"
                      v-model="info.firstname"
                      solo
                      :error-messages="errors"
                      :success="valid"
                      :hide-details="errors.length == 0"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" sm="4" md="5">
                  <form-label isRequired>Last Name (English)</form-label>
                  <ValidationProvider
                    :name="'Last Name'"
                    :rules="{ required: true, regex: /^[aA-zZ ]+$/ }"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      label="Last Name"
                      v-model="info.lastname"
                      solo
                      :error-messages="errors"
                      :success="valid"
                      :hide-details="errors.length == 0"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="4">
                  <form-label isRequired
                    >Date of Birth / วันเดือนปีเกิด</form-label
                  >
                  <v-layout>
                    <v-flex>
                      <template v-if="isMobile">
                        <ValidationProvider
                          :name="'Date of Birth / วันเดือนปีเกิด'"
                          :rules="{ required: true }"
                          v-slot="{ errors, valid }"
                        >
                          <v-text-field
                            label="DD/MM/YYYY"
                            v-model="info.dateOfBirth"
                            solo
                            type="date"
                            :error-messages="errors"
                            :success="valid"
                            :hide-details="errors.length == 0"
                          ></v-text-field>
                        </ValidationProvider>
                      </template>
                      <template v-else>
                        <v-dialog
                          ref="dialog"
                          v-model="showDateOfBirth"
                          :return-value.sync="info.dateOfBirth"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <ValidationProvider
                              :name="'Date of Birth / วันเดือนปีเกิด'"
                              :rules="'required'"
                              v-slot="{ errors, valid }"
                            >
                              <v-text-field
                                v-model="computedDateFormatted"
                                readonly
                                v-on="on"
                                label="DD/MM/YYYY"
                                :error-messages="errors"
                                :success="valid"
                                :hide-details="errors.length == 0"
                                solo
                              ></v-text-field>
                            </ValidationProvider>
                          </template>
                          <v-date-picker
                            v-model="info.dateOfBirth"
                            scrollable
                            color="primary"
                            :max="today"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="showDateOfBirth = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.dialog.save(info.dateOfBirth)"
                              >OK</v-btn
                            >
                          </v-date-picker>
                        </v-dialog>
                      </template>
                    </v-flex>
                    <v-flex
                      class="pt-3 pl-4 pr-4 text-center"
                      style="white-space: nowrap"
                    >
                      อายุ
                      <span class="red--text">{{ computedYearsOld }}</span> ปี
                    </v-flex>
                  </v-layout>
                </v-col>

                <v-col cols="12" sm="6" md="3" lg="4">
                  <form-label isRequired>Nationality / สัญชาติ</form-label>
                  <ValidationProvider
                    :name="'Nationality / สัญชาติ'"
                    :rules="'required'"
                    key="nationality"
                    v-slot="{ errors, valid }"
                  >
                    <v-select
                      v-model="info.nationality"
                      :items="nationalityOptions"
                      menu-props="auto"
                      label="Nationality / สัญชาติ"
                      solo
                      :error-messages="errors"
                      :success="valid"
                      :hide-details="errors.length == 0"
                    ></v-select>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" sm="6" md="3" lg="4">
                  <form-label isRequired>E-mail Address / อีเมล</form-label>
                  <ValidationProvider
                    :name="'E-mail Address / อีเมล'"
                    :rules="'required|email'"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      label="E-mail Address / อีเมล"
                      v-model="info.email"
                      solo
                      type="email"
                      :error-messages="errors"
                      :success="valid"
                      :hide-details="errors.length == 0"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <form-label isRequired
                    >Mobile Phone / หมายเลขโทรศัพท์เคลื่อนที่</form-label
                  >
                  <ValidationProvider
                    :name="'Mobile Phone / หมายเลขโทรศัพท์'"
                    :rules="'required'"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      label="Mobile Phone / หมายเลขโทรศัพท์"
                      solo
                      type="tel"
                      hint="Country Code + Number เช่น ( +66899999999 )"
                      persistent-hint
                      v-model="info.mobilephone"
                      :error-messages="errors"
                      :success="valid"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <form-label>ไลน์ไอดี / Line ID</form-label>
                  <v-text-field
                    label="ไลน์ไอดี / Line ID"
                    v-model="info.lineid"
                    solo
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>

              <div class="text-center mt-4 mb-4">
                <v-btn
                  depressed
                  class="primary ma-1"
                  @click="interest == 'ฝึกงาน' ? goToStep3() : goToStep2()"
                  >Next</v-btn
                >
              </div>
            </template>
            <template v-else-if="step == 2">
              <p>Address / ที่อยู่</p>

              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <form-label isRequired>Country / ประเทศ</form-label>
                  <ValidationProvider
                    :name="'Country / ประเทศ'"
                    :rules="'required'"
                    key="country"
                    v-slot="{ errors, valid }"
                  >
                    <v-select
                      v-model="info.country"
                      menu-props="auto"
                      :items="countryOptions"
                      label="Country / ประเทศ"
                      solo
                      :error-messages="errors"
                      :success="valid"
                      :hide-details="errors.length == 0"
                    ></v-select>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" sm="4" md="4">
                  <form-label isRequired>State/Province / จังหวัด</form-label>
                  <ValidationProvider
                    :name="'State/Province / จังหวัด'"
                    :rules="'required'"
                    key="province"
                    v-slot="{ errors, valid }"
                  >
                    <template v-if="info.country == 'Thailand'">
                      <v-select
                        menu-props="auto"
                        :items="provinceOptions"
                        label="State/Province / จังหวัด"
                        solo
                        :error-messages="errors"
                        :success="valid"
                        :hide-details="errors.length == 0"
                        v-model="info.province"
                      ></v-select>
                    </template>
                    <template v-else>
                      <v-text-field
                        label="State/Province / จังหวัด"
                        solo
                        persistent-hint
                        v-model="info.province"
                        :error-messages="errors"
                        :success="valid"
                        :hide-details="errors.length == 0"
                      ></v-text-field>
                    </template>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" sm="4" md="4"></v-col>

                <v-col cols="12">
                  <form-label isRequired
                    >Address / รายละเอียดที่อยู่</form-label
                  >
                  <v-textarea
                    label="Please fill / โปรดระบุ...."
                    solo
                    hide-details
                    v-model="info.address"
                  ></v-textarea>
                </v-col>
              </v-row>

              <div class="text-center mt-4 mb-4">
                <v-btn depressed class="ma-1" @click="goBack(1)">Back</v-btn>
                <v-btn depressed class="primary ma-1" @click="goToStep3"
                  >Next</v-btn
                >
              </div>
            </template>

            <template v-else-if="step == 3">
              <template v-if="interest != 'ฝึกงาน'">
                <p>Experience</p>
                <div
                  class="mb-2"
                  v-for="(experience, index) in info.experience"
                  :key="`experience_${index}`"
                >
                  <v-card>
                    <v-card-text>
                      <div class="text-right">
                        <v-btn
                          small
                          text
                          color="primary"
                          @click="info.experience.splice(index, 1)"
                        >
                          <v-icon class="mr-1" small>mdi-delete</v-icon>Remove
                        </v-btn>
                      </div>
                      <div class="font-weight-bold">
                        {{ experience.companyname }}
                      </div>
                      <div>{{ experience.position }}</div>
                      <div class="caption grey--text">
                        {{ experience.responsibility }}
                      </div>
                      <div class="caption">
                        {{ experience.startdate }} -
                        <template
                          v-if="experience.enddate && !experience.iscurrent"
                          >{{ experience.enddate }}</template
                        >
                        <template v-else>present</template>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>

                <div class="text-center mt-5 mb-5">
                  <v-btn text color="primary" @click="showExperience = true">
                    <v-icon>mdi-plus</v-icon>Add
                  </v-btn>

                  <v-dialog
                    v-model="showExperience"
                    :key="showExperience"
                    persistent
                    max-width="1040"
                  >
                    <experience
                      @onSave="addExperience"
                      @onClose="showExperience = false"
                    ></experience>
                  </v-dialog>
                </div>
              </template>

              <p><span class="red--text mr-1">*</span>Education</p>

              <div
                class="mb-2"
                v-for="(education, index) in info.education"
                :key="`education_${index}`"
              >
                <v-card>
                  <v-card-text>
                    <div class="text-right">
                      <v-btn
                        small
                        text
                        color="primary"
                        @click="info.education.splice(index, 1)"
                      >
                        <v-icon class="mr-1" small>mdi-delete</v-icon>Remove
                      </v-btn>
                    </div>
                    <div class="font-weight-bold">{{ education.degree }}</div>
                    <div>
                      {{ education.institution }}
                      {{ education.country ? `(${education.country})` : "" }}
                    </div>
                    <div class="grey--text caption">
                      {{ education.faculty }}
                      {{ education.major }}
                      GPA {{ education.gpa }}
                    </div>
                    <div class="caption">
                      {{ education.startdate }} -
                      <template v-if="education.enddate">{{
                        education.enddate
                      }}</template>
                      <template v-else>present</template>
                    </div>
                  </v-card-text>
                </v-card>
              </div>

              <div class="text-center mt-5 mb-5">
                <v-btn text color="primary" @click="showEducation = true">
                  <v-icon>mdi-plus</v-icon>Add
                </v-btn>

                <v-dialog
                  v-model="showEducation"
                  :key="showEducation"
                  persistent
                  max-width="1040"
                >
                  <education
                    @onSave="addEducation"
                    @onClose="showEducation = false"
                  ></education>
                </v-dialog>
              </div>

              <template v-if="interest != 'ฝึกงาน'">
                <p>Language</p>

                <div
                  class="mb-2"
                  v-for="(language, index) in info.language"
                  :key="`language_${index}`"
                >
                  <v-card>
                    <v-card-text>
                      <div class="text-right">
                        <v-btn
                          small
                          text
                          color="primary"
                          @click="info.language.splice(index, 1)"
                        >
                          <v-icon class="mr-1" small>mdi-delete</v-icon>Remove
                        </v-btn>
                      </div>

                      <v-row>
                        <v-col>
                          {{ language.lang }}
                          <div class="caption">{{ language.type }}</div>
                        </v-col>
                        <v-col class="text-right"
                          >Score {{ language.score }}</v-col
                        >
                      </v-row>
                    </v-card-text>
                  </v-card>
                </div>

                <div class="text-center mt-5 mb-5">
                  <v-btn text color="primary" @click="showLanguage = true">
                    <v-icon>mdi-plus</v-icon>Add
                  </v-btn>

                  <v-dialog
                    v-model="showLanguage"
                    :key="showLanguage"
                    persistent
                    max-width="1040"
                  >
                    <language
                      @onSave="addLanguage"
                      @onClose="showLanguage = false"
                    ></language>
                  </v-dialog>
                </div>

                <p>Other Skills</p>

                <div
                  class="mb-2"
                  v-for="(otherskill, index) in info.otherskills"
                  :key="`otherskill_${index}`"
                >
                  <v-card>
                    <v-card-text>
                      <div class="text-right">
                        <v-btn
                          small
                          text
                          color="primary"
                          @click="info.otherskills.splice(index, 1)"
                        >
                          <v-icon class="mr-1" small>mdi-delete</v-icon>Remove
                        </v-btn>
                      </div>

                      <v-row>
                        <v-col>
                          {{ otherskill.category }}
                          <div class="caption">{{ otherskill.skill }}</div>
                        </v-col>
                        <v-col class="text-right"
                          >{{ otherskill.level }} / 10</v-col
                        >
                      </v-row>
                    </v-card-text>
                  </v-card>
                </div>

                <div class="text-center mt-5 mb-5">
                  <v-btn text color="primary" @click="showOtherSkills = true">
                    <v-icon>mdi-plus</v-icon>Add
                  </v-btn>

                  <v-dialog
                    v-model="showOtherSkills"
                    :key="showOtherSkills"
                    persistent
                    max-width="1040"
                  >
                    <other-skills
                      @onSave="addOtherSkills"
                      @onClose="showOtherSkills = false"
                    ></other-skills>
                  </v-dialog>
                </div>
              </template>

              <template v-if="interest != 'สมัครงาน'">
                <p><span class="red--text mr-1">*</span>เลือกโครงการที่สนใจ</p>

                <ValidationProvider
                  :name="'เลือกโครงการที่สนใจ'"
                  :rules="'required'"
                  key="interest_intern"
                  v-slot="{ errors, valid }"
                >
                  <template
                    v-for="interest in [
                      'ฝึกงาน Excellent Internship Program : SUMMER',
                      'ฝึกงานสหกิจศึกษา ภาคเรียนที่ 1 : COOPERATIVE EDUCATION PROGRAM (SEMESTER 1)',
                      'ฝึกงานสหกิจศึกษา ภาคเรียนที่ 2 : COOPERATIVE EDUCATION PROGRAM (SEMESTER 2)',
                    ]"
                  >
                    <v-checkbox
                      v-model="interest_intern"
                      :key="interest"
                      :label="interest"
                      :value="interest"
                      :success="valid"
                      :error="errors.length != 0"
                      :hide-details="true"
                    ></v-checkbox>
                  </template>

                  <p class="caption red--text" v-if="errors.length != 0">
                    {{ errors[0] }}
                  </p>

                  <!-- <v-radio
                      class="mb-2"
                      :key="'ฝึกงาน Excellent Internship Program : SUMMER'"
                      :label="'ฝึกงาน Excellent Internship Program : SUMMER'"
                      :value="'ฝึกงาน Excellent Internship Program : SUMMER'"
                    ></v-radio>

                    <v-radio
                      class="mb-2"
                      :key="'ฝึกงานสหกิจศึกษา ภาคเรียนที่ 1 : COOPERATIVE EDUCATION PROGRAM (SEMESTER 1)'"
                      :label="'ฝึกงานสหกิจศึกษา ภาคเรียนที่ 1 : COOPERATIVE EDUCATION PROGRAM (SEMESTER 1)'"
                      :value="'ฝึกงานสหกิจศึกษา ภาคเรียนที่ 1 : COOPERATIVE EDUCATION PROGRAM (SEMESTER 1)'"
                    ></v-radio>

                    <v-radio
                      class="mb-2"
                      :key="'ฝึกงานสหกิจศึกษา ภาคเรียนที่ 2 : COOPERATIVE EDUCATION PROGRAM (SEMESTER 2)'"
                      :label="'ฝึกงานสหกิจศึกษา ภาคเรียนที่ 2 : COOPERATIVE EDUCATION PROGRAM (SEMESTER 2)'"
                      :value="'ฝึกงานสหกิจศึกษา ภาคเรียนที่ 2 : COOPERATIVE EDUCATION PROGRAM (SEMESTER 2)'"
                    ></v-radio> -->
                </ValidationProvider>

                <p class="mt-4">
                  <span class="red--text mr-1">*</span
                  >โปรดระบุสาขาหรือสายอาชีพที่ต้องการฝึกงาน
                </p>

                <ValidationProvider
                  :name="'โปรดระบุสาขาหรือสายอาชีพที่ต้องการฝึกงาน'"
                  :rules="'required'"
                  key="interest_branch"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    label="โปรดระบุสาขาหรือสายอาชีพที่ต้องการฝึกงาน"
                    v-model="info.interest_branch"
                    solo
                    :error-messages="errors"
                    :success="valid"
                    :hide-details="errors.length == 0"
                  ></v-text-field>
                </ValidationProvider>
              </template>

              <div class="text-center mt-4 mb-4">
                <ValidationProvider
                  :name="'Education'"
                  :rules="'required'"
                  key="education"
                  v-slot="{ errors }"
                >
                  <input type="hidden" v-model="info.education" />
                  <div class="red--text" v-if="errors.length > 0">
                    {{ errors[0] }}
                  </div>
                </ValidationProvider>
                <v-btn depressed class="ma-1" @click="goBack(2)">Back</v-btn>
                <v-btn
                  depressed
                  class="primary ma-1"
                  :loading="loading"
                  @click="
                    hasProfile = true;
                    submitResume();
                  "
                  >Next</v-btn
                >
              </div>
            </template>
          </ValidationObserver>
        </template>
        <template v-else-if="view == 'finish'">
          <v-layout align-center justify-center style="height: 80vh">
            <v-flex>
              <div class="text-center">
                <div style="position: relative">
                  <div
                    style="
                      width: 150px;
                      position: absolute;
                      background: red;
                      height: 150px;
                      left: 50%;
                      top: 50%;
                      margin-left: -75px;
                      margin-top: -80px;
                      opacity: 30%;
                      border-radius: 50%;
                    "
                  ></div>
                  <div
                    style="
                      width: 190px;
                      position: absolute;
                      background: red;
                      height: 190px;
                      left: 50%;
                      top: 50%;
                      margin-left: -95px;
                      margin-top: -100px;
                      opacity: 30%;
                      border-radius: 50%;
                    "
                  ></div>
                  <img
                    :src="`${publicPath}img/logo_scg.png`"
                    style="
                      margin: auto;
                      width: 110px;
                      z-index: 3;
                      position: relative;
                    "
                  />
                </div>
                <div class="grey--text" style="margin-top: 90px">
                  สมัครเรียบร้อยแล้ว
                </div>
              </div>
            </v-flex>
          </v-layout>
        </template>

        <template v-else-if="view == 'generatepdf'">
          <div style="position: relative">
            <div
              style="
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
              "
            >
              <v-row style="height: 100%" justify="center" align="center">
                <v-col class="text-center">
                  <v-progress-circular
                    :size="50"
                    color="grey"
                    indeterminate
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </div>

            <div style="position: relative; z-index: 10">
              <template v-if="preview">
                <iframe
                  :key="previewVersion + '_desktop'"
                  frameborder="0"
                  framemargin="0"
                  :src="preview"
                  class="preview-pane"
                  style="width: 100%; height: 500px; margin: 0px; padding: 0px"
                ></iframe>
              </template>
              <!-- <template v-if="!isInClient">
                <template v-if="isMobile">
                  <embed
                    :key="previewVersion + '_mobile'"
                    @load="onPreviewLoad"
                    :src="
                      `https://scgroadshowresumeserver.herokuapp.com/sendscgresume?userid=${userid}&templateid=${selectedPdfTemplate}#view=FitH`
                    "
                    style="width: 100%; height: 500px; margin: 0px; padding: 0px;"
                  />
                </template>
                <template v-else>
                  <iframe
                    :key="previewVersion + '_desktop'"
                    frameborder="0"
                    framemargin="0"
                    @load="onPreviewLoad"
                    :src="
                      `https://scgroadshowresumeserver.herokuapp.com/sendscgresume?userid=${userid}&templateid=${selectedPdfTemplate}#view=FitH`
                    "
                    class="preview-pane"
                    style="width: 100%; height: 500px; margin: 0px; padding: 0px;"
                  ></iframe>
                </template>
              </template>
              <template v-else>
                <embed
                  :key="previewVersion + '_line'"
                  @load="onPreviewLoad"
                  :src="
                    `https://scgroadshowresumeserver.herokuapp.com/sendscgresume?userid=${userid}&templateid=${selectedPdfTemplate}#view=FitH`
                  "
                  style="width: 100%; height: 500px; margin: 0px; padding: 0px;"
                />
              </template> -->
            </div>
          </div>
          <div class="mt-2 mb-5"></div>
          <div class="text-center">
            <v-btn
              color="primary"
              class="ma-2"
              v-if="isInClient || isAndroid"
              :href="`https://scgroadshowresumeserver.herokuapp.com/scgcareer/send-resume?userid=${userid}&resumeid=${resumeid}&templateid=${selectedPdfTemplate}#view=FitH`"
              target="_blank"
              :loading="loading"
            >
              <v-icon class="mr-1">mdi-download</v-icon>Download
            </v-btn>
            <v-btn
              v-else
              color="primary"
              class="ma-2"
              @click="onDownloadPdf"
              target="_blank"
              :loading="loading"
            >
              <v-icon class="mr-1">mdi-download</v-icon>Download
            </v-btn>
            <v-btn
              color="primary"
              class="ma-2"
              @click="sendResumeToEmail(selectedPdfTemplate)"
              :loading="loading"
            >
              <v-icon class="mr-1">mdi-email</v-icon>Send to email
            </v-btn>

            <div>
              <v-btn
                @click="
                  view = 'createpdf';
                  hasProfile = false;
                "
                class="ma-2"
                :loading="loading"
                >Edit Resume</v-btn
              >
              <v-btn class="ma-2" @click="onClose" :loading="loading"
                >Close</v-btn
              >
              <v-btn
                class="ma-2"
                @click="view = 'applyapplication'"
                :loading="loadingProfile || loading"
                >Apply</v-btn
              >
            </div>
          </div>
        </template>

        <template v-else-if="view == 'applyapplication'">
          <v-layout align-center justify-center style="height: 80vh">
            <v-flex>
              <div class="text-center" style="max-width: 300px; margin: auto">
                <p class="primary--text">ต้องการสมัคร</p>

                <v-btn
                  @click="updateInterest('สมัครงาน')"
                  block
                  :loading="loading"
                  >สมัคร/Apply</v-btn
                >

                <div class="mt-5">
                  <v-btn
                    @click="updateInterest('ฝึกงาน')"
                    block
                    :loading="loading"
                    >ฝึกงาน/Internship</v-btn
                  >
                </div>
              </div>
            </v-flex>
          </v-layout>
        </template>
      </v-container>
    </v-content>
  </div>
</template>

<script>
import Stepper from "@/components/Stepper";
import FormLabel from "@/components/FormLabel";
import Experience from "@/components/Experience";
import Education from "@/components/Education";
import Language from "@/components/Language";
import OtherSkills from "@/components/OtherSkills";
import UploadProfile from "@/components/UploadProfile";
import TermsAndCons from "@/components/TermsAndCons";

import moment from "moment";
import nationality from "@/nationality.js";
import country from "@/country.js";
import province from "@/province.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axios from "axios";
import { isMobile } from "mobile-device-detect";
import goTo from "vuetify/es5/services/goto";
// import jsPDF from "jspdf";
// import { Base64 } from "js-base64";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "@/assets/fonts/vfs_fonts.js";
import resume from "@/resume.js";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew Bold.ttf",
    italics: "THSarabunNew-Italic.ttf",
    bolditalics: "THSarabunNew-BoldItalic.ttf",
  },
};

export default {
  components: {
    Stepper,
    FormLabel,
    Experience,
    ValidationProvider,
    ValidationObserver,
    Education,
    Language,
    OtherSkills,
    UploadProfile,
    TermsAndCons,
  },
  data() {
    return {
      isMobile: isMobile,
      isAndroid: false,
      view: "termsandcons",
      step: 1,
      selectedPdfTemplate: null,
      publicPath: process.env.BASE_URL,
      titleOptions: [
        {
          text: "Mr.",
          value: "Mr.",
        },
        {
          text: "Ms.",
          value: "Ms.",
        },
        {
          text: "Mrs.",
          value: "Mrs.",
        },
      ],
      nationalityOptions: nationality.data,
      countryOptions: country.data,
      provinceOptions: province.data,
      showDateOfBirth: false,
      showExperience: false,
      showEducation: false,
      showLanguage: false,
      showOtherSkills: false,
      dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
      today: moment().format("YYYY-MM-DD"),
      userid: null, //Ue5fcebd532baa37b8406dffbf26ec270
      interest: "",
      objective: "event",
      info: {
        profileimage: null,
        firstname: null,
        lastname: null,
        title: null,
        nationality: "thai",
        mobilephone: null,
        lineid: null,
        dateOfBirth: null,
        experience: [],
        education: [],
        language: [],
        otherskills: [],
        country: "Thailand",
        interest_branch: null,
      },
      interest_intern: [],
      preview: null,
      previewVersion: moment().format("x"),
      showPreview: false,
      isInClient: false,
      sessiontoken: null,
      loading: false,
      loadingProfile: true,
      hasProfile: false,
      resumeid: null,
      // allowApply: true,
    };
  },
  filters: {
    formatDate(value, format) {
      if (!value) {
        return "";
      }
      return moment(value).format(format);
    },
  },
  async mounted() {
    // var beginningTime = moment();
    // var endTime = moment("2020-12-01T00:00");
    // this.allowApply = beginningTime.isBefore(endTime);

    /* eslint-disable no-undef,no-console */
    // https://liff.line.me/1653836511-2WEKEOe3
    // https://liff.line.me/1653856162-wDVO8rmL
    var vm = this;
    this.isInClient = liff.isInClient();
    if (
      (this.$route.query.obj && this.$route.query.obj == "diyresume") ||
      (this.$route.query["liff.state"] &&
        this.$route.query["liff.state"].obj == "diyresume")
    ) {
      this.objective = "diyresume";
    }
    if (
      (this.$route.query.view && this.$route.query.view == "designresume") ||
      (this.$route.query["liff.state"] &&
        this.$route.query["liff.state"].view == "designresume")
    ) {
      this.view = "createpdf";
    }

    if (
      (this.$route.query.view &&
        this.$route.query.view == "generatepdf" &&
        this.$route.query.templateid) ||
      (this.$route.query["liff.state"] &&
        this.$route.query["liff.state"].view == "generatepdf" &&
        this.$route.query["liff.state"].templateid)
    ) {
      let templateid = null;
      if (this.$route.query.templateid) {
        templateid = this.$route.query.templateid;
      } else if (this.$route.query["liff.state"].templateid) {
        templateid = this.$route.query["liff.state"].templateid;
      }

      if (templateid != null && templateid > 0 && templateid <= 4) {
        this.selectedPdfTemplate = templateid;
        this.view = "generatepdf";
      }
    }

    try {
      await liff.init({
        liffId: process.env.VUE_APP_LINE_LIFF,
        // liffId: "1653856162-wDVO8rmL",
      });
      if (!liff.isLoggedIn()) {
        // set `redirectUri` to redirect the user to a URL other than the front page of your LIFF app.
        var redirectUri =
          this.objective == "diyresume"
            ? process.env.VUE_APP_LINE_LIFF_URL + "?view=register&obj=diyresume"
            : process.env.VUE_APP_LINE_LIFF_URL + "?view=register";
        liff.login({
          redirectUri: redirectUri,
        });
      } else {
        var profile = await liff.getProfile();
        vm.userid = profile.userId;
        var info = await this.getUserProfile();

        this.isAndroid = liff.getOS() == "android";
        if (info) {
          this.info = info;
          this.hasProfile = true;
        }
        this.loadingProfile = false;
      }
    } catch (err) {
      (err) => console.error(err.code, err.message);
    }
    /* eslint-enable no-undef,no-console */
  },
  methods: {
    async updateInterest(type) {
      this.interest = type;
      this.loading = true;
      var response = await this.saveResume({ sendmail: true });
      this.sessiontoken = response.data.data
        ? response.data.data.sessiontoken
        : null;
      this.view = "finish";

      this.loading = false;
    },
    onPreviewLoad() {
      this.showPreview = true;
    },
    onClose() {
      /* eslint-disable no-undef */

      if (!liff.isInClient()) {
        close();
        location.reload();
      } else {
        liff.closeWindow();
      }
      /* eslint-enable no-undef */
    },
    async onGeneratePdf() {
      this.view = this.hasProfile ? "generatepdf" : "register";
      this.showPreview = false;
      this.createPdf();
      /* eslint-disable no-undef,no-empty */
      if (!liff.isInClient()) {
      } else {
        await liff.sendMessages([
          {
            type: "text",
            text: "สร้าง resume เรียบร้อยแล้ว",
          },
        ]);
      }
      /* eslint-enable no-undef,no-empty */
    },
    async onDownloadPdf() {
      this.loading = true;
      await this.createPdf("download");
      this.loading = false;
    },
    async createPdf(type = "iframe") {
      var profileimageBase64 = null;
      if (this.info.profileimage) {
        try {
          profileimageBase64 = await this.getBase64Image(
            this.info.profileimage
          );
        } catch (error) {
          /* eslint-disable no-console */
          // console.log(error);
          /* eslint-enable no-console */
          profileimageBase64 = resume.profile;
        }
      } else {
        profileimageBase64 = resume.profile;
      }
      var profileimage = await this.getBase64CircleImage(profileimageBase64);

      this.showPreview = false;
      if (pdfMake.vfs == undefined) {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }
      pdfMake.fonts = {
        THSarabunNew: {
          normal: "THSarabunNew.ttf",
          bold: "THSarabunNew Bold.ttf",
          italics: "THSarabunNew Italic.ttf",
          bolditalics: "THSarabunNew BoldItalic.ttf",
        },
        Roboto: {
          normal: "Roboto-Regular.ttf",
          bold: "Roboto-Medium.ttf",
          italics: "Roboto-Italic.ttf",
          bolditalics: "Roboto-MediumItalic.ttf",
        },
      };

      var docDefinition = null;
      switch (this.selectedPdfTemplate) {
        case 1:
          docDefinition = this.getPdfTemplate1(profileimage, this.info);
          break;
        case 2:
          docDefinition = this.getPdfTemplate2(profileimage, this.info);
          break;
        case 3:
          docDefinition = this.getPdfTemplate3(profileimage, this.info);
          break;
        case 4:
          docDefinition = this.getPdfTemplate4(profileimage, this.info);
          break;
      }

      var createPdf = pdfMake.createPdf(docDefinition);
      if (type == "iframe") {
        createPdf.getDataUrl(
          function (outDoc) {
            this.preview = outDoc;
            this.showPreview = true;
            this.previewVersion = moment().format("x");
          }.bind(this)
        );
      } else if (type == "download") {
        createPdf.download();
      }
    },
    openNewWindowPDF() {
      var options = options || {};
      if (typeof options === "string") {
        options = {
          filename: options,
        };
      } else {
        options.filename = options.filename || "generated.pdf";
      }
      /* eslint-disable no-useless-escape,no-undef */
      if (!liff.isInClient()) {
        var pdfObjectUrl =
          options.pdfObjectUrl ||
          "https://cdnjs.cloudflare.com/ajax/libs/pdfobject/2.1.1/pdfobject.min.js";
        var htmlForNewWindow = `<html><style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style><body><script src="${pdfObjectUrl}"><\/script><script>PDFObject.embed("${
          this.preview
        }", ${JSON.stringify(options)});<\/script></body></html>`;

        var nW = global.open();
        if (nW !== null) {
          nW.document.write(htmlForNewWindow);
        }
        return nW;
      } else {
        liff.openWindow({
          url: this.preview,
          external: true,
        });
        location.href = this.preview;
        window.location.href = this.preview;
      }
      /* eslint-enable no-useless-escape,no-undef */
    },
    getPdfTemplate1(profileimage, info) {
      return {
        background: function () {
          return [{ image: "bg1" }];
        },

        content: [
          {
            columns: [
              {
                width: 200,
                stack: [
                  {
                    image: profileimage,
                    width: 120,
                    alignment: "center",
                    margin: [0, 0, 0, 30],
                  },
                  {
                    columns: [
                      {
                        width: 10,
                        svg: `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                  viewBox="0 0 384 384" style="enable-background:new 0 0 384 384;" xml:space="preserve">
                                <g>
                                  <g>
                                    <path d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.719-3.656-23.896-0.302-30.438,6.417l-43.177,32.594
                                      c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448
                                      c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.813C13.823,0,0,13.823,0,30.813
                                      C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z"
                                      />
                                  </g>
                                </g>
                                </svg>`,
                        margin: [10, 10, 0, 0],
                      },
                      {
                        stack: [
                          {
                            text: `Phone\n`,
                            bold: true,
                          },
                          {
                            text: `${info.mobilephone}`,
                          },
                        ],
                        margin: [20, 0, 0, 0],
                      },
                    ],
                    margin: [0, 0, 0, 10],
                  },
                  {
                    columns: [
                      {
                        width: 10,
                        svg: `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                              <g>
                                <g>
                                  <path d="M467,61H45c-6.927,0-13.412,1.703-19.279,4.51L255,294.789l51.389-49.387c0,0,0.004-0.005,0.005-0.007
                                    c0.001-0.002,0.005-0.004,0.005-0.004L486.286,65.514C480.418,62.705,473.929,61,467,61z"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M507.496,86.728L338.213,256.002L507.49,425.279c2.807-5.867,4.51-12.352,4.51-19.279V106
                                    C512,99.077,510.301,92.593,507.496,86.728z"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M4.51,86.721C1.703,92.588,0,99.073,0,106v300c0,6.923,1.701,13.409,4.506,19.274L173.789,256L4.51,86.721z"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M317.002,277.213l-51.396,49.393c-2.93,2.93-6.768,4.395-10.605,4.395s-7.676-1.465-10.605-4.395L195,277.211
                                    L25.714,446.486C31.582,449.295,38.071,451,45,451h422c6.927,0,13.412-1.703,19.279-4.51L317.002,277.213z"/>
                                </g>
                              </g>

                              </svg>`,
                        margin: [10, 10, 0, 0],
                      },
                      {
                        stack: [
                          {
                            text: `Email\n`,
                            bold: true,
                          },
                          {
                            text: `${info.email}`,
                          },
                        ],
                        margin: [20, 0, 0, 0],
                      },
                    ],
                    margin: [0, 0, 0, 10],
                  },
                  {
                    columns: [
                      {
                        width: 10,
                        svg: '<svg id="Bold" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m12 .5c-6.615 0-12 4.398-12 9.803 0 4.841 4.27 8.897 10.035 9.668.391.083.923.26 1.058.594.12.303.079.771.038 1.087l-.164 1.026c-.045.303-.24 1.193 1.049.649 1.291-.542 6.916-4.104 9.436-7.019 1.724-1.9 2.548-3.847 2.548-6.005 0-5.405-5.385-9.803-12-9.803zm-4.697 13.017h-2.386c-.345 0-.63-.287-.63-.633v-4.801c0-.347.285-.634.63-.634.348 0 .63.287.63.634v4.167h1.756c.348 0 .629.285.629.634 0 .346-.282.633-.629.633zm2.466-.633c0 .346-.282.633-.631.633-.345 0-.627-.287-.627-.633v-4.801c0-.347.282-.634.63-.634.346 0 .628.287.628.634zm5.741 0c0 .272-.174.513-.432.6-.064.021-.133.031-.199.031-.211 0-.391-.091-.51-.252l-2.443-3.338v2.958c0 .346-.279.633-.631.633-.346 0-.626-.287-.626-.633v-4.8c0-.272.173-.513.43-.599.06-.023.136-.033.194-.033.195 0 .375.105.495.256l2.462 3.351v-2.975c0-.347.282-.634.63-.634.345 0 .63.287.63.634zm3.855-3.035c.349 0 .63.287.63.635 0 .347-.281.634-.63.634h-1.755v1.132h1.755c.349 0 .63.285.63.634 0 .346-.281.633-.63.633h-2.386c-.345 0-.627-.287-.627-.633v-4.801c0-.347.282-.634.63-.634h2.386c.346 0 .627.287.627.634 0 .351-.281.634-.63.634h-1.755v1.132z"/></svg>',
                        margin: [10, 10, 0, 0],
                      },
                      {
                        stack: [
                          {
                            text: `Line ID\n`,
                            bold: true,
                          },
                          {
                            text: info.lineid ? info.lineid : "-",
                          },
                        ],
                        margin: [20, 0, 0, 0],
                      },
                    ],
                    margin: [0, 0, 0, 10],
                  },
                  {
                    columns: [
                      {
                        width: 10,
                        svg: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                              viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                            <g>
                              <g>
                                <path d="M256,0C161.896,0,85.333,76.563,85.333,170.667c0,28.25,7.063,56.26,20.49,81.104L246.667,506.5
                                  c1.875,3.396,5.448,5.5,9.333,5.5s7.458-2.104,9.333-5.5l140.896-254.813c13.375-24.76,20.438-52.771,20.438-81.021
                                  C426.667,76.563,350.104,0,256,0z M256,256c-47.052,0-85.333-38.281-85.333-85.333c0-47.052,38.281-85.333,85.333-85.333
                                  s85.333,38.281,85.333,85.333C341.333,217.719,303.052,256,256,256z"/>
                              </g>
                            </g>
                            </svg>`,
                        margin: [10, 10, 0, 0],
                      },
                      {
                        stack: [
                          {
                            text: `Address\n`,
                            bold: true,
                          },
                          {
                            text: `${info.address} ${info.province} ${info.country}`,
                          },
                        ],
                        margin: [20, 0, 0, 0],
                      },
                    ],
                    margin: [0, 0, 0, 10],
                  },
                  {
                    text: `LANGUAGES\n`,
                    style: "bigger",
                    margin: [0, 10, 0, 0],
                  },
                  {
                    stack: [this.getLanguage(info.language)],
                  },
                  {
                    canvas: [
                      {
                        type: "line",
                        x1: 0,
                        y1: 0,
                        x2: 170,
                        y2: 0,
                        lineWidth: 1,
                      },
                    ],
                    margin: [0, 20],
                  },
                  {
                    text: `OTHER SKILLS\n`,
                    style: "bigger",
                  },
                  {
                    stack: [this.getOtherSkills(info.otherskills)],
                  },
                ],
                margin: [20, 0],
              },
              {
                width: "*",
                stack: [
                  {
                    text: `${info.title} ${info.firstname} ${info.lastname}\n`,
                    bold: true,
                    fontSize: 32,
                  },
                  {
                    text: `EDUCATION\n`,
                    style: "bigger",
                    margin: [0, 0, 0, 10],
                  },
                  this.getEducation(info.education),
                  {
                    canvas: [
                      {
                        type: "line",
                        x1: 0,
                        y1: 0,
                        x2: 315,
                        y2: 0,
                        lineWidth: 1,
                      },
                    ],
                    margin: [0, 20],
                  },
                  {
                    text: `WORK EXPERIENCE\n`,
                    style: "bigger",
                    margin: [0, 0, 0, 10],
                  },
                  this.getExperience(info.experience),
                ],
                margin: [10, 0],
              },
            ],
          },
        ],

        images: {
          bg1: resume.bg1,
        },

        defaultStyle: {
          font: "THSarabunNew",
        },
        styles: {
          bigger: {
            fontSize: 20,
            bold: true,
          },
        },
      };
    },
    getPdfTemplate2(profileimage, info) {
      return {
        background: function () {
          return [{ image: "bg1" }];
        },

        content: [
          {
            columns: [
              {
                width: 180,
                color: "#ffffff",
                stack: [
                  {
                    image: profileimage,
                    width: 120,
                    alignment: "center",
                    margin: [0, 0, 0, 30],
                  },
                  {
                    columns: [
                      {
                        width: 10,
                        svg: `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                  viewBox="0 0 384 384" style="enable-background:new 0 0 384 384;fill: white" xml:space="preserve">
                                <g>
                                  <g>
                                    <path d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.719-3.656-23.896-0.302-30.438,6.417l-43.177,32.594
                                      c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448
                                      c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.813C13.823,0,0,13.823,0,30.813
                                      C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z"
                                      />
                                  </g>
                                </g>
                                </svg>`,
                        margin: [10, 10, 0, 0],
                      },
                      {
                        stack: [
                          {
                            text: `Phone\n`,
                            bold: true,
                          },
                          {
                            text: `${info.mobilephone}`,
                          },
                        ],
                        margin: [20, 0, 0, 0],
                      },
                    ],
                    margin: [0, 0, 0, 10],
                  },
                  {
                    columns: [
                      {
                        width: 10,
                        svg: `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;fill: white" xml:space="preserve">
                              <g>
                                <g>
                                  <path d="M467,61H45c-6.927,0-13.412,1.703-19.279,4.51L255,294.789l51.389-49.387c0,0,0.004-0.005,0.005-0.007
                                    c0.001-0.002,0.005-0.004,0.005-0.004L486.286,65.514C480.418,62.705,473.929,61,467,61z"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M507.496,86.728L338.213,256.002L507.49,425.279c2.807-5.867,4.51-12.352,4.51-19.279V106
                                    C512,99.077,510.301,92.593,507.496,86.728z"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M4.51,86.721C1.703,92.588,0,99.073,0,106v300c0,6.923,1.701,13.409,4.506,19.274L173.789,256L4.51,86.721z"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M317.002,277.213l-51.396,49.393c-2.93,2.93-6.768,4.395-10.605,4.395s-7.676-1.465-10.605-4.395L195,277.211
                                    L25.714,446.486C31.582,449.295,38.071,451,45,451h422c6.927,0,13.412-1.703,19.279-4.51L317.002,277.213z"/>
                                </g>
                              </g>

                              </svg>`,
                        margin: [10, 10, 0, 0],
                      },
                      {
                        stack: [
                          {
                            text: `Email\n`,
                            bold: true,
                          },
                          {
                            text: `${info.email}`,
                          },
                        ],
                        margin: [20, 0, 0, 0],
                      },
                    ],
                    margin: [0, 0, 0, 10],
                  },
                  {
                    columns: [
                      {
                        width: 10,
                        svg: '<svg id="Bold" style="fill: white" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m12 .5c-6.615 0-12 4.398-12 9.803 0 4.841 4.27 8.897 10.035 9.668.391.083.923.26 1.058.594.12.303.079.771.038 1.087l-.164 1.026c-.045.303-.24 1.193 1.049.649 1.291-.542 6.916-4.104 9.436-7.019 1.724-1.9 2.548-3.847 2.548-6.005 0-5.405-5.385-9.803-12-9.803zm-4.697 13.017h-2.386c-.345 0-.63-.287-.63-.633v-4.801c0-.347.285-.634.63-.634.348 0 .63.287.63.634v4.167h1.756c.348 0 .629.285.629.634 0 .346-.282.633-.629.633zm2.466-.633c0 .346-.282.633-.631.633-.345 0-.627-.287-.627-.633v-4.801c0-.347.282-.634.63-.634.346 0 .628.287.628.634zm5.741 0c0 .272-.174.513-.432.6-.064.021-.133.031-.199.031-.211 0-.391-.091-.51-.252l-2.443-3.338v2.958c0 .346-.279.633-.631.633-.346 0-.626-.287-.626-.633v-4.8c0-.272.173-.513.43-.599.06-.023.136-.033.194-.033.195 0 .375.105.495.256l2.462 3.351v-2.975c0-.347.282-.634.63-.634.345 0 .63.287.63.634zm3.855-3.035c.349 0 .63.287.63.635 0 .347-.281.634-.63.634h-1.755v1.132h1.755c.349 0 .63.285.63.634 0 .346-.281.633-.63.633h-2.386c-.345 0-.627-.287-.627-.633v-4.801c0-.347.282-.634.63-.634h2.386c.346 0 .627.287.627.634 0 .351-.281.634-.63.634h-1.755v1.132z"/></svg>',
                        margin: [10, 10, 0, 0],
                      },
                      {
                        stack: [
                          {
                            text: `Line ID\n`,
                            bold: true,
                          },
                          {
                            text: info.lineid ? info.lineid : "-",
                          },
                        ],
                        margin: [20, 0, 0, 0],
                      },
                    ],
                    margin: [0, 0, 0, 10],
                  },
                  {
                    columns: [
                      {
                        width: 10,
                        svg: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                              viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;fill: white" xml:space="preserve">
                            <g>
                              <g>
                                <path d="M256,0C161.896,0,85.333,76.563,85.333,170.667c0,28.25,7.063,56.26,20.49,81.104L246.667,506.5
                                  c1.875,3.396,5.448,5.5,9.333,5.5s7.458-2.104,9.333-5.5l140.896-254.813c13.375-24.76,20.438-52.771,20.438-81.021
                                  C426.667,76.563,350.104,0,256,0z M256,256c-47.052,0-85.333-38.281-85.333-85.333c0-47.052,38.281-85.333,85.333-85.333
                                  s85.333,38.281,85.333,85.333C341.333,217.719,303.052,256,256,256z"/>
                              </g>
                            </g>
                            </svg>`,
                        margin: [10, 10, 0, 0],
                      },
                      {
                        stack: [
                          {
                            text: `Address\n`,
                            bold: true,
                          },
                          {
                            text: `${info.address} ${info.province} ${info.country}`,
                          },
                        ],
                        margin: [20, 0, 0, 0],
                      },
                    ],
                    margin: [0, 0, 0, 10],
                  },
                  {
                    text: `LANGUAGES\n`,
                    style: "bigger",
                    margin: [0, 10, 0, 0],
                  },
                  {
                    stack: [this.getLanguage(info.language)],
                  },
                  {
                    canvas: [
                      {
                        type: "line",
                        x1: 0,
                        y1: 0,
                        x2: 150,
                        y2: 0,
                        lineWidth: 1,
                        color: "#ffffff",
                      },
                    ],
                    margin: [0, 20],
                  },
                  {
                    text: `OTHER SKILLS\n`,
                    style: "bigger",
                  },
                  {
                    stack: [this.getOtherSkills(info.otherskills)],
                  },
                ],
                margin: [0, 0, 30, 0],
              },
              {
                width: "*",
                stack: [
                  {
                    text: `${info.title} ${info.firstname} ${info.lastname}\n`,
                    bold: true,
                    fontSize: 32,
                    margin: [0, 0, 0, 20],
                    color: "#1a5275",
                  },
                  {
                    text: `EDUCATION\n`,
                    style: "bigger",
                    margin: [0, 0, 0, 10],
                    color: "#1a5275",
                  },
                  this.getEducation(info.education),
                  {
                    canvas: [
                      {
                        type: "line",
                        x1: 0,
                        y1: 0,
                        x2: 265,
                        y2: 0,
                        lineWidth: 1,
                        color: "#cccccb",
                      },
                    ],
                    margin: [0, 20],
                  },
                  {
                    text: `WORK EXPERIENCE\n`,
                    style: "bigger",
                    margin: [0, 0, 0, 10],
                    color: "#1a5275",
                  },
                  this.getExperience(info.experience),
                ],
                margin: [40, 0, 10, 0],
              },
            ],
          },
        ],

        images: {
          bg1: resume.bg2,
        },

        defaultStyle: {
          font: "THSarabunNew",
        },
        styles: {
          bigger: {
            fontSize: 20,
            bold: true,
          },
        },
      };
    },
    getPdfTemplate3(profileimage, info) {
      return {
        background: function () {
          return [{ image: "bg1" }];
        },

        content: [
          {
            image: profileimage,
            width: 120,
            alignment: "center",
            margin: [0, 40, 0, 0],
          },
          {
            text: `${info.title} ${info.firstname} ${info.lastname}\n`.toUpperCase(),
            bold: true,
            fontSize: 32,
            alignment: "center",
            color: "#990066",
            margin: [0, 10, 0, 20],
          },
          {
            columns: [
              {
                columns: [
                  {
                    width: 10,
                    svg: `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                  viewBox="0 0 384 384" style="enable-background:new 0 0 384 384;" xml:space="preserve">
                                <g>
                                  <g>
                                    <path d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.719-3.656-23.896-0.302-30.438,6.417l-43.177,32.594
                                      c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448
                                      c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.813C13.823,0,0,13.823,0,30.813
                                      C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z"
                                      />
                                  </g>
                                </g>
                                </svg>`,
                    margin: [10, 0, 0, 0],
                  },
                  {
                    stack: [
                      {
                        text: `${info.mobilephone}`,
                      },
                    ],
                    margin: [20, 0, 0, 0],
                  },
                ],
                margin: [0, 0, 0, 10],
              },
              {
                columns: [
                  {
                    width: 10,
                    svg: `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                              <g>
                                <g>
                                  <path d="M467,61H45c-6.927,0-13.412,1.703-19.279,4.51L255,294.789l51.389-49.387c0,0,0.004-0.005,0.005-0.007
                                    c0.001-0.002,0.005-0.004,0.005-0.004L486.286,65.514C480.418,62.705,473.929,61,467,61z"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M507.496,86.728L338.213,256.002L507.49,425.279c2.807-5.867,4.51-12.352,4.51-19.279V106
                                    C512,99.077,510.301,92.593,507.496,86.728z"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M4.51,86.721C1.703,92.588,0,99.073,0,106v300c0,6.923,1.701,13.409,4.506,19.274L173.789,256L4.51,86.721z"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M317.002,277.213l-51.396,49.393c-2.93,2.93-6.768,4.395-10.605,4.395s-7.676-1.465-10.605-4.395L195,277.211
                                    L25.714,446.486C31.582,449.295,38.071,451,45,451h422c6.927,0,13.412-1.703,19.279-4.51L317.002,277.213z"/>
                                </g>
                              </g>

                              </svg>`,
                    margin: [10, 0, 0, 0],
                  },
                  {
                    stack: [
                      {
                        text: `${info.email}`,
                      },
                    ],
                    margin: [20, 0, 0, 0],
                  },
                ],
                margin: [0, 0, 0, 10],
              },
              {
                columns: [
                  {
                    width: 10,
                    svg: '<svg id="Bold" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m12 .5c-6.615 0-12 4.398-12 9.803 0 4.841 4.27 8.897 10.035 9.668.391.083.923.26 1.058.594.12.303.079.771.038 1.087l-.164 1.026c-.045.303-.24 1.193 1.049.649 1.291-.542 6.916-4.104 9.436-7.019 1.724-1.9 2.548-3.847 2.548-6.005 0-5.405-5.385-9.803-12-9.803zm-4.697 13.017h-2.386c-.345 0-.63-.287-.63-.633v-4.801c0-.347.285-.634.63-.634.348 0 .63.287.63.634v4.167h1.756c.348 0 .629.285.629.634 0 .346-.282.633-.629.633zm2.466-.633c0 .346-.282.633-.631.633-.345 0-.627-.287-.627-.633v-4.801c0-.347.282-.634.63-.634.346 0 .628.287.628.634zm5.741 0c0 .272-.174.513-.432.6-.064.021-.133.031-.199.031-.211 0-.391-.091-.51-.252l-2.443-3.338v2.958c0 .346-.279.633-.631.633-.346 0-.626-.287-.626-.633v-4.8c0-.272.173-.513.43-.599.06-.023.136-.033.194-.033.195 0 .375.105.495.256l2.462 3.351v-2.975c0-.347.282-.634.63-.634.345 0 .63.287.63.634zm3.855-3.035c.349 0 .63.287.63.635 0 .347-.281.634-.63.634h-1.755v1.132h1.755c.349 0 .63.285.63.634 0 .346-.281.633-.63.633h-2.386c-.345 0-.627-.287-.627-.633v-4.801c0-.347.282-.634.63-.634h2.386c.346 0 .627.287.627.634 0 .351-.281.634-.63.634h-1.755v1.132z"/></svg>',
                    margin: [10, 0, 0, 0],
                  },
                  {
                    stack: [
                      {
                        text: info.lineid ? info.lineid : "-",
                      },
                    ],
                    margin: [20, 0, 0, 0],
                  },
                ],
                margin: [0, 0, 0, 10],
              },
            ],
          },
          {
            columns: [
              {
                width: 10,
                svg: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                              viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                            <g>
                              <g>
                                <path d="M256,0C161.896,0,85.333,76.563,85.333,170.667c0,28.25,7.063,56.26,20.49,81.104L246.667,506.5
                                  c1.875,3.396,5.448,5.5,9.333,5.5s7.458-2.104,9.333-5.5l140.896-254.813c13.375-24.76,20.438-52.771,20.438-81.021
                                  C426.667,76.563,350.104,0,256,0z M256,256c-47.052,0-85.333-38.281-85.333-85.333c0-47.052,38.281-85.333,85.333-85.333
                                  s85.333,38.281,85.333,85.333C341.333,217.719,303.052,256,256,256z"/>
                              </g>
                            </g>
                            </svg>`,
                margin: [10, 0, 0, 0],
              },
              {
                stack: [
                  {
                    text: `${info.address} ${info.province} ${info.country}`,
                  },
                ],
                margin: [20, 0, 0, 0],
              },
            ],
            margin: [0, 0, 0, 10],
          },
          {
            canvas: [
              {
                type: "line",
                x1: 200,
                y1: 0,
                x2: 300,
                y2: 0,
                lineWidth: 1,
                color: "#990066",
              },
            ],
            margin: [0, 20],
          },
          {
            columns: [
              {
                stack: [
                  {
                    text: `LANGUAGES\n`,
                    style: "bigger",
                    margin: [0, 0, 0, 0],
                  },
                  {
                    stack: [this.getLanguage(info.language)],
                  },
                  {
                    text: `OTHER SKILLS\n`,
                    style: "bigger",
                  },
                  {
                    stack: [this.getOtherSkills(info.otherskills)],
                  },
                ],
                margin: [20, 0],
              },
              {
                stack: [
                  {
                    text: `EDUCATION\n`,
                    style: "bigger",
                    margin: [0, 0, 0, 10],
                  },
                  this.getEducation(info.education),
                  {
                    text: `WORK EXPERIENCE\n`,
                    style: "bigger",
                    margin: [0, 0, 0, 10],
                  },
                  this.getExperience(info.experience),
                ],
                margin: [10, 0],
              },
            ],
          },
        ],

        images: {
          bg1: resume.bg3,
        },

        defaultStyle: {
          font: "THSarabunNew",
        },
        styles: {
          bigger: {
            fontSize: 20,
            bold: true,
            color: "#990066",
          },
        },
      };
    },
    getPdfTemplate4(profileimage, info) {
      return {
        background: function () {
          return [{ image: "bg1" }];
        },

        content: [
          {
            columns: [
              {
                width: 120,
                stack: [
                  {
                    image: profileimage,
                    width: 120,
                    alignment: "center",
                    margin: [0, 0, 0, 30],
                  },
                ],
                margin: [30, 100, 0, 0],
              },
              {
                width: "*",
                stack: [
                  {
                    text: `${info.title} ${info.firstname} ${info.lastname}\n`,
                    bold: true,
                    fontSize: 35,
                  },
                  {
                    columns: [
                      {
                        width: 10,
                        svg: `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                  viewBox="0 0 384 384" style="enable-background:new 0 0 384 384;" xml:space="preserve">
                                <g>
                                  <g>
                                    <path d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.719-3.656-23.896-0.302-30.438,6.417l-43.177,32.594
                                      c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448
                                      c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.813C13.823,0,0,13.823,0,30.813
                                      C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z"
                                      />
                                  </g>
                                </g>
                                </svg>`,
                        margin: [10, 10, 0, 0],
                      },
                      {
                        stack: [
                          {
                            text: `Phone\n`,
                            bold: true,
                          },
                          {
                            text: `${info.mobilephone}`,
                          },
                        ],
                        margin: [20, 0, 0, 0],
                      },
                      {
                        width: 10,
                        svg: '<svg id="Bold" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m12 .5c-6.615 0-12 4.398-12 9.803 0 4.841 4.27 8.897 10.035 9.668.391.083.923.26 1.058.594.12.303.079.771.038 1.087l-.164 1.026c-.045.303-.24 1.193 1.049.649 1.291-.542 6.916-4.104 9.436-7.019 1.724-1.9 2.548-3.847 2.548-6.005 0-5.405-5.385-9.803-12-9.803zm-4.697 13.017h-2.386c-.345 0-.63-.287-.63-.633v-4.801c0-.347.285-.634.63-.634.348 0 .63.287.63.634v4.167h1.756c.348 0 .629.285.629.634 0 .346-.282.633-.629.633zm2.466-.633c0 .346-.282.633-.631.633-.345 0-.627-.287-.627-.633v-4.801c0-.347.282-.634.63-.634.346 0 .628.287.628.634zm5.741 0c0 .272-.174.513-.432.6-.064.021-.133.031-.199.031-.211 0-.391-.091-.51-.252l-2.443-3.338v2.958c0 .346-.279.633-.631.633-.346 0-.626-.287-.626-.633v-4.8c0-.272.173-.513.43-.599.06-.023.136-.033.194-.033.195 0 .375.105.495.256l2.462 3.351v-2.975c0-.347.282-.634.63-.634.345 0 .63.287.63.634zm3.855-3.035c.349 0 .63.287.63.635 0 .347-.281.634-.63.634h-1.755v1.132h1.755c.349 0 .63.285.63.634 0 .346-.281.633-.63.633h-2.386c-.345 0-.627-.287-.627-.633v-4.801c0-.347.282-.634.63-.634h2.386c.346 0 .627.287.627.634 0 .351-.281.634-.63.634h-1.755v1.132z"/></svg>',
                        margin: [10, 10, 0, 0],
                      },
                      {
                        stack: [
                          {
                            text: `Line ID\n`,
                            bold: true,
                          },
                          {
                            text: info.lineid ? info.lineid : "-",
                          },
                        ],
                        margin: [20, 0, 0, 0],
                      },
                    ],
                    margin: [0, 0, 0, 10],
                  },
                  {
                    columns: [
                      {
                        width: 10,
                        svg: `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                              <g>
                                <g>
                                  <path d="M467,61H45c-6.927,0-13.412,1.703-19.279,4.51L255,294.789l51.389-49.387c0,0,0.004-0.005,0.005-0.007
                                    c0.001-0.002,0.005-0.004,0.005-0.004L486.286,65.514C480.418,62.705,473.929,61,467,61z"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M507.496,86.728L338.213,256.002L507.49,425.279c2.807-5.867,4.51-12.352,4.51-19.279V106
                                    C512,99.077,510.301,92.593,507.496,86.728z"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M4.51,86.721C1.703,92.588,0,99.073,0,106v300c0,6.923,1.701,13.409,4.506,19.274L173.789,256L4.51,86.721z"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M317.002,277.213l-51.396,49.393c-2.93,2.93-6.768,4.395-10.605,4.395s-7.676-1.465-10.605-4.395L195,277.211
                                    L25.714,446.486C31.582,449.295,38.071,451,45,451h422c6.927,0,13.412-1.703,19.279-4.51L317.002,277.213z"/>
                                </g>
                              </g>

                              </svg>`,
                        margin: [10, 10, 0, 0],
                      },
                      {
                        stack: [
                          {
                            text: `Email\n`,
                            bold: true,
                          },
                          {
                            text: `${info.email}`,
                          },
                        ],
                        margin: [20, 0, 0, 0],
                      },
                    ],
                    margin: [0, 0, 0, 10],
                  },
                ],
                margin: [30, 100, 0, 0],
              },
            ],
          },

          {
            columns: [
              {
                width: 10,
                svg: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                      <g>
                        <g>
                          <path d="M256,0C161.896,0,85.333,76.563,85.333,170.667c0,28.25,7.063,56.26,20.49,81.104L246.667,506.5
                            c1.875,3.396,5.448,5.5,9.333,5.5s7.458-2.104,9.333-5.5l140.896-254.813c13.375-24.76,20.438-52.771,20.438-81.021
                            C426.667,76.563,350.104,0,256,0z M256,256c-47.052,0-85.333-38.281-85.333-85.333c0-47.052,38.281-85.333,85.333-85.333
                            s85.333,38.281,85.333,85.333C341.333,217.719,303.052,256,256,256z"/>
                        </g>
                      </g>
                      </svg>`,
                margin: [10, 10, 0, 0],
              },
              {
                stack: [
                  {
                    text: `Address\n`,
                    bold: true,
                  },
                  {
                    text: `${info.address} ${info.province} ${info.country}`,
                  },
                ],
                margin: [20, 0, 0, 0],
              },
            ],
            margin: [0, 0, 0, 10],
          },
          {
            canvas: [
              {
                type: "line",
                x1: 200,
                y1: 0,
                x2: 300,
                y2: 0,
                lineWidth: 1,
              },
            ],
            margin: [0, 20],
          },
          {
            columns: [
              {
                stack: [
                  {
                    text: `EDUCATION\n`,
                    style: "bigger",
                    margin: [0, 0, 0, 10],
                  },
                  this.getEducation(info.education),
                ],
                margin: [10, 0],
              },
              {
                stack: [
                  {
                    text: `WORK EXPERIENCE\n`,
                    style: "bigger",
                    margin: [0, 0, 0, 10],
                  },
                  this.getExperience(info.experience),
                ],
                margin: [10, 0],
              },
            ],
          },
          {
            columns: [
              {
                stack: [
                  {
                    text: `LANGUAGES\n`,
                    style: "bigger",
                  },
                  {
                    stack: [this.getLanguage(info.language)],
                  },
                ],
                margin: [10, 0],
              },
              {
                stack: [
                  {
                    text: `OTHER SKILLS\n`,
                    style: "bigger",
                  },
                  {
                    stack: [this.getOtherSkills(info.otherskills)],
                  },
                ],
                margin: [10, 0],
              },
            ],
          },
        ],

        images: {
          bg1: resume.bg4,
        },

        defaultStyle: {
          font: "THSarabunNew",
        },
        styles: {
          bigger: {
            fontSize: 20,
            bold: true,
            color: "#339999",
          },
        },
      };
    },
    getBase64Image(url) {
      return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
          var reader = new FileReader();
          reader.onloadend = function () {
            resolve(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.onerror = function (e) {
          reject(e);
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
      });
    },
    getBase64CircleImage(blob) {
      return new Promise((resolve) => {
        var img = document.createElement("img");
        img.src = blob;
        img.onload = () => {
          // Create canvas to convert the image to base64
          var canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.width;

          var ctx = canvas.getContext("2d");

          this.roundedImage(
            ctx,
            0,
            0,
            canvas.width,
            canvas.width,
            canvas.width / 2 + 10
          );
          ctx.clip();

          // draw the image on the canvas (the user can't see it).
          ctx.drawImage(img, 0, 0);
          ctx.restore();

          // document.body.appendChild(canvas);

          // set the link's attribute to replace the icon
          resolve(canvas.toDataURL());
        };
      });
    },
    getExperience(experiences) {
      var result = [];
      experiences.map((exp) => {
        result.push({
          stack: [
            {
              text: `${exp.companyname}\n`,
              fontSize: 12,
              bold: true,
            },
            {
              text: `${exp.position}\n`,
            },
            {
              text: `${exp.responsibility}\n`,
              fontSize: 10,
              color: "#969696",
            },
            {
              text: `${exp.startdate} - ${
                exp.enddate && !exp.iscurrent ? exp.enddate : "Present"
              }\n`,
              fontSize: 10,
              margin: [0, 0, 0, 10],
            },
          ],
        });
      });

      return result;
    },
    getEducation(educations) {
      var result = [];
      educations.map((edu) => {
        result.push({
          stack: [
            {
              text: `${edu.degree}\n`,
              fontSize: 12,
              bold: true,
            },
            {
              text: `${edu.institution} ${
                edu.country ? `(${edu.country})` : ""
              }`,
            },
            {
              text: `${edu.faculty ? `${edu.faculty}` : ""} ${
                edu.major ? `${edu.major}` : ""
              } ${edu.gpa ? `GPA ${edu.gpa}` : ""}`,
              fontSize: 10,
              color: "#969696",
            },
            {
              text: `${edu.startdate} - ${
                edu.enddate ? edu.enddate : "Present"
              }\n`,
              fontSize: 10,
            },
          ],
        });
      });

      return result;
    },
    getLanguage(languages) {
      var result = [];
      languages.map((lang) => {
        result.push({
          columns: [
            { text: `${lang.lang} ${lang.type ? `(${lang.type})` : ""}` },
            { text: lang.score, alignment: "right" },
          ],
        });
      });

      return result;
    },
    getOtherSkills(otherskills) {
      var result = [];
      otherskills.map((skill) => {
        result.push({
          columns: [
            {
              text: `${skill.category} ${
                skill.skill ? `(${skill.skill})` : ""
              }`,
            },
            { text: skill.level, alignment: "right" },
          ],
        });
      });

      return result;
    },
    async getUserProfile() {
      var url = `https://api-enterprise.zwiz.app/scgcareer/applicant?userid=${this.userid}`;
      var response = await axios.get(url);
      if (response.data.responsecode == 200) {
        /* eslint-disable no-console */
        var data = response.data.data;
        var profile = null;
        if (data.length > 0) {
          profile = {
            profileimage: data[0].photourl,
            firstname: data[0].first_name,
            lastname: data[0].last_name,
            title: data[0].title,
            nationality: data[0].nationality,
            mobilephone: data[0].phoneno,
            lineid: data[0].lineid,
            dateOfBirth: data[0].dob,
            province: data[0].province,
            address: data[0].addresstext,
            experience: [],
            education: [],
            language: [],
            otherskills: [],
            country: data[0].country,
            email: data[0].email,
          };

          if (data[0].resumeid) this.resumeid = data[0].resumeid;

          this.interest = data[0].interest;

          try {
            profile.experience = JSON.parse(data[0].experiencejson);
          } catch (error) {
            /* eslint-disable no-console */
            console.error(error);
            /* eslint-enable no-console */
          }

          try {
            profile.education = JSON.parse(data[0].educationjson);
          } catch (error) {
            /* eslint-disable no-console */
            console.error(error);
            /* eslint-enable no-console */
          }

          try {
            profile.language = JSON.parse(data[0].languagejson);
          } catch (error) {
            /* eslint-disable no-console */
            console.error(error);
            /* eslint-enable no-console */
          }

          try {
            profile.otherskills = JSON.parse(data[0].skilljson);
          } catch (error) {
            /* eslint-disable no-console */
            console.error(error);
            /* eslint-enable no-console */
          }
        }
        /* eslint-enable no-console */

        return profile;
      } else {
        return null;
      }
    },
    roundedImage(ctx, x, y, width, height, radius) {
      ctx.beginPath();
      ctx.moveTo(x + radius, y);
      ctx.lineTo(x + width - radius, y);
      ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
      ctx.lineTo(x + width, y + height - radius);
      ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width - radius,
        y + height
      );
      ctx.lineTo(x + radius, y + height);
      ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
      ctx.lineTo(x, y + radius);
      ctx.quadraticCurveTo(x, y, x + radius, y);
      ctx.closePath();
    },
    async submitResume() {
      var isValid = await this.$refs.observer.validate();

      if (!isValid) {
        this.$nextTick(() => {
          this.$vuetify.goTo(".error--text", {
            duration: 300,
            offset: 0,
            easing: "easeInOutCubic",
          });
        });
        return false;
      }

      this.loading = true;
      var response = null;

      if (this.objective != "event") {
        response = await this.saveResume();
        this.sessiontoken = response.data.data
          ? response.data.data.sessiontoken
          : null;
        this.onGeneratePdf();
      } else {
        response = await this.saveResume({ sendmail: true });
        this.sessiontoken = response.data.data
          ? response.data.data.sessiontoken
          : null;
        this.view = "finish";
      }

      this.loading = false;
    },
    async saveResume(options) {
      var url = "https://api-enterprise.zwiz.app/scgcareer/applicant";

      var data = {
        userid: this.userid,
        photourl: this.info.profileimage,
        title: this.info.title,
        first_name: this.info.firstname,
        last_name: this.info.lastname,
        dob: this.info.dateOfBirth ? this.info.dateOfBirth : "",
        nationality: this.info.nationality,
        email: this.info.email,
        phoneno: this.info.mobilephone,
        lineid: this.info.lineid,
        country: this.info.country,
        province: this.info.province,
        addresstext: this.info.address,
        experiencejson: JSON.stringify(this.info.experience),
        educationjson: JSON.stringify(this.info.education),
        languagejson: JSON.stringify(this.info.language),
        skilljson: JSON.stringify(this.info.otherskills),
        interest: this.interest,
        urlsource: this.objective,
        sendmail: options && options.sendmail ? options.sendmail : false,
        createnew: options && options.createnew ? options.createnew : false,
        interest_intern: this.interest_intern
          ? this.interest_intern.join(",")
          : "",
        interest_branch: this.info.interest_branch,
      };

      try {
        var response = await axios.post(url, data);
        if (response.data.responsecode == 200) {
          if (response.data.data) this.resumeid = response.data.data.resumeid;
          return Promise.resolve(response);
        } else {
          throw response.data.responsecode;
        }
      } catch (error) {
        // eslint-disable-next-line no-undef
        this.$swal.fire({
          title: "Unsccessful upload",
          text: "Please try it again",
          icon: "error",
        });
      }
    },
    addExperience(payload) {
      this.showExperience = false;
      this.info.experience.push(payload);
    },
    addEducation(payload) {
      this.showEducation = false;
      this.info.education.push(payload);
    },
    addLanguage(payload) {
      this.showLanguage = false;
      this.info.language.push(payload);
    },
    addOtherSkills(payload) {
      this.showOtherSkills = false;
      this.info.otherskills.push(payload);
    },
    goBack(val) {
      this.step = val;
      this.$nextTick(() => {
        goTo(0);
      });
    },
    async goToStep2() {
      var isValid = await this.$refs.observer.validate();

      if (!isValid) {
        this.$nextTick(() => {
          this.$vuetify.goTo(".error--text", {
            duration: 300,
            offset: 0,
            easing: "easeInOutCubic",
          });
        });
        return false;
      }

      this.step = 2;
      this.$nextTick(() => {
        goTo(0);
      });
    },
    async goToStep3() {
      var isValid = await this.$refs.observer.validate();

      if (!isValid) {
        this.$nextTick(() => {
          this.$vuetify.goTo(".error--text", {
            duration: 300,
            offset: 0,
            easing: "easeInOutCubic",
          });
        });
        return false;
      }

      this.step = 3;
      this.$nextTick(() => {
        goTo(0);
      });
    },
    async sendResumeToEmail(templateid) {
      this.loading = true;
      var url = `https://api-enterprise.zwiz.app/scgcareer/send-resume-email`;
      var params = {
        userid: this.userid,
        resumeid: this.resumeid,
        templateid: templateid,
      };

      try {
        var response = await axios.post(url, null, {
          params: params,
          headers: { AUTHORIZATION: "Bearer xxx xxx" },
        });
        if (response.data.responsecode == 200) {
          this.loading = false;
          // eslint-disable-next-line
          this.$swal.fire({
            title: "Successful",
            icon: "success",
          });
        } else {
          // eslint-disable-next-line
          this.$swal.fire({
            title: "Unsccessful send resume to email",
            text: "Please try it again",
            icon: "error",
          });
        }
      } catch (error) {
        this.loading = false;
        // eslint-disable-next-line no-undef
        this.$swal.fire({
          title: "Unsccessful send resume to email",
          text: "Please try it again",
          icon: "error",
        });
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.info.dateOfBirth);
    },
    computedYearsOld() {
      if (this.info.dateOfBirth == null || this.info.dateOfBirth == "") {
        return 0;
      }
      var now = moment();
      return now.diff(moment(this.info.dateOfBirth), "years");
    },
  },
  watch: {
    "info.dateOfBirth": function () {
      this.dateFormatted = this.formatDate(this.info.dateOfBirth);
    },
  },
};
</script>

<style lang="scss" scoped></style>

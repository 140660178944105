<template>
  <ValidationObserver ref="observer" v-slot="{}" tag="form">
    <v-card>
      <v-card-title class="primary white--text">
        <v-row no-gutters>
          <v-col>Experience</v-col>
          <v-col class="text-right">
            <v-btn text fab small dark @click="$emit('onClose')">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <div class="mb-3 mt-3">
          <v-row>
            <v-col cols="12" sm="6">
              <form-label isRequired>Company’s Name / ชื่อบริษัท</form-label>
              <ValidationProvider
                :name="'Company’s Name / ชื่อบริษัท'"
                :rules="'required'"
                v-slot="{ errors, valid }"
              >
                <v-text-field
                  label="Company’s Name / ชื่อบริษัท"
                  solo
                  persistent-hint
                  v-model="experience.companyname"
                  :error-messages="errors"
                  :success="valid"
                  :hide-details="errors.length == 0"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6">
              <form-label isRequired>Position / ตำแหน่ง</form-label>
              <ValidationProvider
                :name="'Position / ตำแหน่ง'"
                :rules="'required'"
                v-slot="{ errors, valid }"
              >
                <v-text-field
                  label="Position / ตำแหน่ง"
                  solo
                  persistent-hint
                  v-model="experience.position"
                  :error-messages="errors"
                  :success="valid"
                  :hide-details="errors.length == 0"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12">
              <form-label>Responsibility / สิ่งที่รับผิดชอบ</form-label>
              <v-textarea
                label="Please fill / โปรดระบุ...."
                solo
                hide-details
                v-model="experience.responsibility"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="experience.iscurrent"
                :label="`Is this your current employer? ยังคงเป็นพนักงานที่นี่หรือไม่`"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="6">
              <form-label isRequired>Start Date / วันเริ่มทำงาน</form-label>

              <ValidationProvider
                :name="'Start Date / วันเริ่มทำงาน'"
                :rules="'required'"
                key="country"
                v-slot="{ errors, valid }"
              >
                <v-select
                  v-model="experience.startdate"
                  menu-props="auto"
                  :items="computedYear"
                  label="Start Date / วันเริ่มทำงาน"
                  solo
                  :error-messages="errors"
                  :success="valid"
                  :hide-details="errors.length == 0"
                ></v-select>
              </ValidationProvider>

              <!-- <v-dialog
                ref="startDate"
                key="startDate"
                v-model="showStartDate"
                :return-value.sync="experience.startdate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <ValidationProvider
                    :name="'Start Date / วันเริ่มทำงาน'"
                    :rules="'required'"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="computedStartDateFormatted"
                      readonly
                      v-on="on"
                      label="DD/MM/YYYY"
                      :error-messages="errors"
                      :success="valid"
                      :hide-details="errors.length == 0"
                      solo
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <v-date-picker v-model="experience.startdate" scrollable color="primary">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="showStartDate = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.startDate.save(experience.startdate)">OK</v-btn>
                </v-date-picker>
              </v-dialog>-->
            </v-col>
            <v-col cols="12" sm="6">
              <template v-if="!experience.iscurrent">
                <form-label isRequired>End Date / วันสิ้นสุด</form-label>

                <ValidationProvider
                  :name="'End Date / วันสิ้นสุด'"
                  :rules="'required'"
                  key="country"
                  v-slot="{ errors, valid }"
                >
                  <v-select
                    v-model="experience.enddate"
                    menu-props="auto"
                    :items="computedYear"
                    label="End Date / วันสิ้นสุด"
                    solo
                    :error-messages="errors"
                    :success="valid"
                    :hide-details="errors.length == 0"
                  ></v-select>
                </ValidationProvider>
              </template>
            </v-col>
          </v-row>
        </div>
        <div class="text-center">
          <v-btn depressed class="ma-1" @click="$emit('onClose')">Cancel</v-btn>
          <v-btn depressed class="primary ma-1" @click="onSave">Save</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </ValidationObserver>
</template>

<script>
import FormLabel from "@/components/FormLabel";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import moment from "moment";
export default {
  data() {
    return {
      showStartDate: false,
      showEndDate: false,
      experience: {
        companyname: null,
        position: null,
        iscurrent: false,
        responsibility: null,
        startdate: null,
        enddate: null
      }
    };
  },
  components: {
    FormLabel,
    ValidationProvider,
    ValidationObserver
  },
  methods: {
    async onSave() {
      var isValid = await this.$refs.observer.validate();

      if (!isValid) {
        this.$nextTick(() => {
          this.$vuetify.goTo(".error--text", {
            duration: 300,
            offset: 0,
            easing: "easeInOutCubic",
            container: ".v-dialog"
          });
        });
        return false;
      }

      this.$emit("onSave", this.experience);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  },
  computed: {
    computedYear() {
      var currentYear = parseInt(moment().format("YYYY"));
      var years = [];
      for (let index = currentYear; index >= currentYear - 60; index--) {
        years.push(index);
      }
      return years;
    },
    computedStartDateFormatted() {
      return this.formatDate(this.experience.startdate);
    },
    computedEndDateFormatted() {
      return this.formatDate(this.experience.enddate);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
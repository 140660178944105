<template>
  <div class="md-stepper-horizontal">
    <div class="md-step active">
      <div class="md-step-circle">
        <span>1</span>
      </div>
      <div class="md-step-title">
        <span class="hidden-sm-and-up">Info.</span>
        <span class="hidden-xs-only">Information</span>
      </div>
      <div class="md-step-bar-left"></div>
      <div class="md-step-bar-right"></div>
    </div>
    <template v-if="type != 'ฝึกงาน'">
      <div class="md-step" :class="{ active: value >= 2 }">
        <div class="md-step-circle">
          <span>2</span>
        </div>
        <div class="md-step-title">Address</div>
        <div class="md-step-bar-left"></div>
        <div class="md-step-bar-right"></div>
      </div>
    </template>
    <div class="md-step" :class="{ active: value == 3 }">
      <div class="md-step-circle">
        <span>{{ type == "ฝึกงาน" ? "2" : "3" }}</span>
      </div>
      <div class="md-step-title">{{ type == "ฝึกงาน" ? "Education" : "Exprience" }}</div>
      <div class="md-step-bar-left"></div>
      <div class="md-step-bar-right"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value", "type"],
};
</script>

<style lang="scss" scoped>
.md-stepper-horizontal {
  display: table;
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
}
.md-stepper-horizontal .md-step {
  display: table-cell;
  position: relative;
  padding: 24px;
}
.md-stepper-horizontal .md-step:hover,
.md-stepper-horizontal .md-step:active {
  background-color: rgba(0, 0, 0, 0.04);
}
.md-stepper-horizontal .md-step:active {
  border-radius: 15% / 75%;
}
.md-stepper-horizontal .md-step:first-child:active {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.md-stepper-horizontal .md-step:last-child:active {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.md-stepper-horizontal .md-step:hover .md-step-circle {
  background-color: #757575;
}
.md-stepper-horizontal .md-step:first-child .md-step-bar-left,
.md-stepper-horizontal .md-step:last-child .md-step-bar-right {
  display: none;
}
.md-stepper-horizontal .md-step .md-step-circle {
  width: 30px;
  height: 30px;
  margin: 0 auto;
  background-color: #cecece;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  color: #ffffff;
}
.md-stepper-horizontal.green .md-step.active .md-step-circle {
  background-color: #00ae4d;
}
.md-stepper-horizontal.orange .md-step.active .md-step-circle {
  background-color: #f96302;
}
.md-stepper-horizontal .md-step.active .md-step-circle {
  background-color: red;
}

.md-stepper-horizontal .md-step.done .md-step-circle {
  background-color: red;
}

.md-stepper-horizontal .md-step.done .md-step-circle:before {
  font-family: "FontAwesome";
  font-weight: 100;
  content: "\f00c";
}
.md-stepper-horizontal .md-step.done .md-step-circle *,
.md-stepper-horizontal .md-step.editable .md-step-circle * {
  display: none;
}
.md-stepper-horizontal .md-step.editable .md-step-circle {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.md-stepper-horizontal .md-step.editable .md-step-circle:before {
  font-family: "FontAwesome";
  font-weight: 100;
  content: "\f040";
}
.md-stepper-horizontal .md-step .md-step-title {
  margin-top: 16px;
  font-size: 16px;
}
.md-stepper-horizontal .md-step .md-step-title,
.md-stepper-horizontal .md-step .md-step-optional {
  text-align: center;
  color: rgba(0, 0, 0, 0.26);
}
.md-stepper-horizontal .md-step.active .md-step-title {
  color: rgba(0, 0, 0, 0.87);
}
.md-stepper-horizontal .md-step.active.done .md-step-title,
.md-stepper-horizontal .md-step.active.editable .md-step-title {
}
.md-stepper-horizontal .md-step .md-step-optional {
  font-size: 12px;
}
.md-stepper-horizontal .md-step.active .md-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.md-stepper-horizontal .md-step .md-step-bar-left,
.md-stepper-horizontal .md-step .md-step-bar-right {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 1px solid #dddddd;
}
.md-stepper-horizontal .md-step .md-step-bar-right {
  right: 0;
  left: 50%;
  margin-left: 20px;
}
.md-stepper-horizontal .md-step .md-step-bar-left {
  left: 0;
  right: 50%;
  margin-right: 20px;
}
</style>

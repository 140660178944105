<template>
  <v-app>
    <template v-if="!loading">
      <router-view></router-view>
    </template>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      loading: true,
      publicPath: process.env.BASE_URL,
    };
  },

  components: {},
  async beforeCreate() {
    try {
      // https://liff.line.me/1653856162-wDVO8rmL?view=workstyle
      // eslint-disable-next-line
      await this.$liff.init({
        // liffId: "1653856162-wDVO8rmL",
        liffId: process.env.VUE_APP_LINE_LIFF,
      });
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
    this.loading = false;
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "rsu";
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/RSU_Regular.ttf");
}

.rsu {
  font-family: "rsu", sans-serif !important;
  font-size: 1.15rem;
}
</style>

import { extend,configure } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import i18n from "./i18n";
configure({
  defaultMessage: (field, values) => {
    // override the field name.
    values._field_ = i18n.t(`${field}`);

    return i18n.t(`validation.${values._rule_}`, values);
  }
});


// loop over all rules
for (let rule in rules) {
    extend(rule, {
        ...rules[rule], // add the rule
    });
}
export default {
  university: [
    "Asian Institute of Technology (AIT)",
    "Asian University",
    "Asia - Pacific International University",
    "Assumption University",
    "Bangkok Thonburi University",
    "Bangkok University",
    "Bansomdejchaopraya Rajabhat University",
    "Burapha University",
    "Buriram Rajabhat University",
    "Chaiyaphum Rajabhat University",
    "Chandrakasem Rajabhat University",
    "Chaopraya University",
    "Chiang Mai University",
    "Chiang Mai Rajabhat University",
    "Chiangrai Rajabhat University",
    "Christian University",
    "Chulalongkorn University",
    "College of Asian Scholars",
    "Dhonburi Rajabhat University",
    "Dhurakijpundit University",
    "Eastern Asia University",
    "Far Eastern University",
    "Hatyai University",
    "Hua Chiew Chalermprakiet University",
    "Kalasin Rajabhat University",
    "Kamphaeng Phet Rajabhat University",
    "Kanchanaburi Rajabhat University",
    "Kasem Bundit University",
    "Kasetsart University",
    "Kasetsart University Sriracha Campus",
    "Kasetsart University Kamphaeng Saen Campus",
    "Khon Kaen University",
    "King Mongkut’s Institute of Technology Ladkrabang",
    "King Mongkut’s University of Technology North Bangkok",
    "King Mongkut’s University of Technology Thonburi",
    "Kirk University",
    "Lampang Inter-Tech College",
    "Lampang Rajabhat University",
    "Loei Rajabhat University",
    "Mae Fah Luang University",
    "Maejo University",
    "Mahachulalongkornrajavidyalaya University",
    "Mahanakorn University of Technology",
    "Mahasarakham University",
    "Mahamakut Buddhist University",
    "Mahidol University",
    "Mahidol University International College",
    "Merchant Marine Training Center",
    "Muban Chom Bueng Rajabhat University",
    "Nakhon Pathom Rajabhat University",
    "Nakhon Phanom University",
    "Nakhon Ratchasima Rajabhat University",
    "Nakhon Sawan Rajabhat University",
    "Nakhon Si Thammarat Rajabhat University",
    "Naresuan University",
    "Nation University",
    "National Institute of Development Administration",
    "Navamindradhiraj University",
    "North Bangkok University",
    "North Chiang Mai University",
    "Northeastern University",
    "Panyapiwat Institute of Management",
    "Pathumthani University",
    "Payap University",
    "The Petroleum and Petrochemical College",
    "Phakklang University",
    "Phayao University",
    "Phetchabun Rajabhat University",
    "Phetchaburi Rajabhat University",
    "Phitsanulok University",
    "Phranakhon Rajabhat University",
    "Phranakhon Si Ayutthaya Rajabhat University",
    "Phuket Rajabhat University",
    "Pibulsongkram Rajabhat University",
    "Prince of Songkla University",
    "Princess of Narathiwas University",
    "Rajabhat Maha Sarakham University",
    "Rajamangala University of Technology Isan",
    "Rajamangala University of Technology Krungthep",
    "Rajamangala University of Technology Phranakon",
    "Rajamangala University of Technology Rattanakosin",
    "Rajamangala University of Technology Srivijaya",
    "Rajamangala University of Technology Suvanabhumi",
    "Rajamangala University of Technology Tawan-Ok",
    "Rajamangala University of Technology Thanyaburi",
    "Rajanagarindra Rajabhat University",
    "Rajapruk University",
    "Rambhaibarni Rajabhat University",
    "Ramkhamhaeng University",
    "Rangsit University",
    "Ratchatani University",
    "Rattana Bundit University",
    "Roi-Et Rajabhat University",
    "Saint John’s University",
    "Sakon Nakhon Rajabhat University",
    "Sasin Graduate Institute of Business Administration",
    "Shinawatra University",
    "Siam University",
    "Silpakorn University",
    "Sirindhorn International Institute of Technology (SIIT)",
    "Sisaket Rajabhat University",
    "Songkhla Rajabhat University",
    "Southeast Asia University",
    "Srinakharinwirot University",
    "Sripatum University",
    "Stamford International University",
    "Suan Dusit Rajabhat University",
    "Suan Sunandha Rajabhat University",
    "Sukhothai Thammathirat Open University",
    "Suranaree University of Technology",
    "Suratthani Rajabhat University",
    "Surindra Rajabhat University",
    "Thai-Nichi Institute of Technology",
    "Thaksin University",
    "Thammasat University",
    "The Eastern University of Management and Technology",
    "The University of The Thai Chamber of Commerce",
    "Thepsatri Rajabhat University",
    "Thonburi University",
    "Ubon Ratchathani Rajabhat University",
    "Ubon Ratchathani University",
    "Udon Thani Rajabhat University",
    "Uttaradit Rajabhat University",
    "Valaya Alongkorn Rajabhat University",
    "Vongchavalitkul University",
    "Walailak University",
    "Webster University",
    "Western University",
    "Yala Islamic University",
    "Yala Rajabhat University",
    "Yonok University",
    "Others - Please specify in Other Educational Institution",
  ],
  faculty: [
    "Agriculture, Agro-Industry, Forestry / เกษตรศาสตร์, อุตสาหกรรมการเกษตร, วนศาสตร์",
    "Architecture / สถาปัตยกรรมศาสตร์",
    "Arts, Liberal Arts  / อักษรศาสตร์, ศิลปศาสตร์",
    "Business Administration, Commerce & Accountancy / บริหารธุรกิจ, พาณิชยศาสตร์และการบัญชี",
    "Communication Arts, Journalism / นิเทศศาสตร์, วารสารศาสตร์",
    "Economics / เศรษฐศาสตร์",
    "Education, Industrial Education  / ศึกษาศาสตร์, ครุศาสตร์อุตสาหกรรม",
    "Engineering  / วิศวกรรมศาสตร์",
    "Fine and Applied Arts  / ศิลปกรรมศาสตร์",
    "Information Technology, Information Science / เทคโนโลยีสารสนเทศ, วิทยาการสารสนเทศ",
    "Laws / นิติศาสตร์",
    "Political Science, Public Administration / รัฐศาสตร์, รัฐประศาสนศาสตร์",
    "Science and Technology / วิทยาศาสตร์และเทคโนโลยี",
    "Social Sciences, Social Work  / สังคมศาสตร์, สังคมสงเคราะห์",
    "Public Health / สาธารณสุขศาสตร์",
    "คณะอื่นๆ โปรดระบุ / Others faculty, Please specify",
  ],
  major: [
    "Accounting / การบัญชี",
    "Accounting Information System / ระบบสารสนเทศทางการบัญชี",
    "Advertising, Broadcasting / การโฆษณา, การกระจายเสียง",
    "Aerospace Engineering / วิศวกรรมการบินและอวกาศยาน",
    "Agribusiness, Agricultural Industry / ธุรกิจการเกษตร, อุตสาหกรรมการเกษตร ",
    "Agricultural Chemistry / เคมีการเกษตร",
    "Agricultural Communication / นิเทศศาสตร์เกษตร",
    "Agricultural Economics / เศรษฐศาสตร์เกษตร",
    "Agricultural Engineering / วิศวกรรมเกษตร",
    "Agro-Industrial Product Development / พัฒนาผลิตภัณฑ์อุตสาหกรรมเกษตร",
    "Agronomy, Horticulture, Soil Science / พืชไร่, พืชสวน, ปฐพีวิทยา",
    "Animation, 3D-Based Design / แอนนิเมชันและการออกแบบสามมิติ",
    "Applied Chemistry / เคมีประยุกต์",
    "Applied Computer Science-Multimedia / วิทยาการคอมพิวเตอร์ประยุกต์-มัลติมีเดีย",
    "Applied Polymer Science and Textile Technology / วิทยาศาสตร์พอลิเมอร์ประยุกต์และเทคโนโลยีสิ่งทอ",
    "Architecture / สถาปัตยกรรม",
    "Arts of Architecture / ศิลปอุตสาหกรรม",
    "Automation Engineering / วิศวกรรมอัตโนมัติ",
    "Automotive Engineering / วิศวกรรมยานยนต์",
    "Biochemistry / ชีวเคมี",
    "Biomedical Engineering / วิศวกรรมชีวการแพทย์",
    "Biotechnology Technology / เทคโนโลยีชีวภาพ",
    "Business Administration / บริหารธุรกิจ",
    "Business Intelligence and Analytic / ธุรกิจอัจฉริยะและการวิเคราะห์",
    "Ceramic, Ceramic Technology /  เซรามิก, เทคโนโลยีเซรามิก",
    "Chemical Engineering / วิศวกรรมเคมี",
    "Chemical Technology / เคมีเทคนิค",
    "Chemistry / เคมี ",
    "Chinese / ภาษาจีน",
    "Civil Engineering / วิศวกรรมโยธา",
    "Civil-Water Resources Engineering / วิศวกรรมโยธา-ทรัพยากรน้ำ",
    "Commercial and Business Law / กฎหมายธุรกิจและการค้าระหว่างประเทศ ",
    "Communication Arts / นิเทศศาสตร์",
    "Communication Design / นิเทศศิลป์",
    "Computer Engineering/ วิศวกรรมคอมพิวเตอร์",
    "Computer Science / วิทยาศาสตร์คอมพิวเตอร์",
    "Construction and Management / การบริหารการก่อสร้าง",
    "Cooperatives Economics / เศรษฐศาสตร์สหกรณ์",
    "Data Science / วิทยาการข้อมูล",
    "Development Communication / นิเทศศาสตร์พัฒนาการ",
    "Digital Marketing / การตลาดดิจิทัล",
    "Digital media / ดิจิทัลมีเดีย ",
    "Dramatic Arts / ศิลปการละคร",
    "Economics / เศรษฐศาสตร์",
    "Electrical and Systems Engineering / วิศวกรรมไฟฟ้าและระบบ",
    "Electrical Engineering, Electronics Engineering / วิศวกรรมไฟฟ้า, วิศวกรรมอิเล็กทรอนิกส์",
    "Electrical-Mechanical Manufacturing Engineering / วิศวกรรมไฟฟ้าเครื่องกลการผลิต",
    "Energy, Energy Technology / พลังงาน, เทคโนโลยีพลังงาน",
    "Engineering Business, Engineering Management  / ธุรกิจวิศวกรรม, การจัดการทางวิศวกรรม",
    "English, Business English / ภาษาอังกฤษ, ภาษาอังกฤษธุรกิจ",
    "Environmental and Natural Resources Economics / เศรษฐศาสตร์สิ่งแวดล้อมและทรัพยากรธรรมชาติ",
    "Environmental Engineering / วิศวกรรมสิ่งแวดล้อม",
    "Environmental Science / วิทยาศาสตร์สิ่งแวดล้อม",
    "Finance / การเงิน",
    "Fisheries / ประมง",
    "Forestry, Forest Management / วนศาสตร์, การจัดการป่าไม้",
    "French / ภาษาฝรั่งเศส",
    "Geography / ภูมิศาสตร์ ",
    "Geology, Georesources / ธรณีวิทยา, ทรัพยากรธรณี",
    "German / ภาษาเยอรมัน",
    "Government / การปกครอง",
    "History / ประวัติศาสตร์",
    "Human Resource and Organization Development / การพัฒนาทรัพยากรมนุษย์และองค์การ",
    "Human Resource Management / การจัดการทรัพยากรมนุษย์",
    "Imaging and Printing Technology / เทคโนโลยีทางภาพและการพิมพ์",
    "Industrial Chemistry / เคมีอุตสาหกรรม",
    "Industrial Engineering / วิศวกรรมอุตสาหการ",
    "Industrial Management / การจัดการอุตสาหกรรม",
    "Industrial Product Design, Industrial Design / ออกแบบผลิตภัณฑ์อุตสาหกรรม, ออกแบบอุตสาหกรรม",
    "Information Studies / สารสนเทศศึกษา",
    "Information Technology, Information Technology Business / เทคโนโลยีสารสนเทศ, เทคโนโลยีสารสนเทศทางธุรกิจ",
    "Innovation and Entrepreneurship / การจัดการนวัตกรรมและการเป็นเจ้าของธุรกิจ",
    "Inorganic Chemistry / เคมีอนินทรีย์",
    "Instrumention and Control System Engineering / วิศวกรรมเครื่องมือและระบบควบคุม  ",
    "Insurance / ประกันภัย",
    "Intellectual Property / ทรัพย์สินทางปัญญา",
    "Interior Architecture / สถาปัตยกรรมภายใน",
    "International Business Management / การจัดการธุรกิจระหว่างประเทศ",
    "International Economics / เศรษฐศาสตร์ระหว่างประเทศ",
    "International Marketing and Brand Management / การตลาดระหว่างประเทศและการจัดการตราสินค้า",
    "International Relations / ความสัมพันธ์ระหว่างประเทศ",
    "Italian  / ภาษาอิตาเลียน",
    "Japanese / ภาษาญี่ปุ่น",
    "Journalism / วารสารสนเทศ",
    "Labour and Human Resource Economics / เศรษฐศาสตร์แรงงานและทรัพยากรมนุษย์",
    "Labour Relations and Development / แรงงานสัมพันธ์กับการพัฒนา",
    "Landscape Architecture / ภูมิสถาปัตยกรรม",
    "Laws, Business Law / กฎหมาย, กฎหมายธุรกิจ",
    "Linguistics / อักษรศาสตร์หรือภาษาศาสตร์",
    "Literature / วรรณคดี",
    "Logistics Engineering / วิศวกรรมโลจิสติกส์",
    "Logistics Management / การจัดการโลจิสติกส์",
    "Management / การจัดการ",
    "Management Information System / ระบบสารสนเทศทางการจัดการ",
    "Management, Business Strategy and Policy / การจัดการ, การจัดการกลยุทธ์ทางธุรกิจ",
    "Managerial Accounting / บัญชีบริหาร",
    "Marketing / การตลาด",
    "Mass Communication / การสื่อสารมวลชน",
    "Materials Engineering / วิศวกรรมวัสดุ",
    "Materials Science, Materials Technology / วัสดุศาสตร์, เทคโนโลยีวัสดุ",
    "Mathematical Statistics / สถิติคณิตศาสตร์",
    "Mathematics / คณิตศาสตร์",
    "Mechanical Engineering / วิศวกรรมเครื่องกล",
    "Mechatronics Engineering / วิศวกรรมเมคคาทรอนิกส์",
    "Metallurgical Engineering, Metallurgical & Materials / วิศวกรรมโลหการ, วิศวกรรมโลหการและวัสดุ",
    "Microbiology / จุลชีววิทยา",
    "Mining / เหมืองแร่",
    "Monetary Economics / เศรษฐศาสตร์การเงิน",
    "Motion Pictures and Still Photography / การภาพยนตร์และภาพนิ่ง",
    "Nano Technology / นาโนเทคโนโลยี",
    "Nautical Sciences / การเดินเรือ",
    "New media / สื่อใหม่",
    "Occupational Health and Safety / อาชีวอนามัยและความปลอดภัย (จป.)",
    "Operations Management / การจัดการการผลิต",
    "Organization and Management / การบริหารองค์กร",
    "Packaging Technology / เทคโนโลยีการบรรจุ",
    "Painting and Media Arts / จิตรกรรมและมีเดียอาตส์",
    "Painting, Sculpture and Printmaking /  จิตรกรรม ประติมากรรมและภาพพิมพ์",
    "Pali and Sanskrit / ภาษาบาลีและสันสกฤต",
    "Petrochemistry, Petrochemical Technology / ปิโตรเคมี, เทคโนโลยีปิโตรเคมี ",
    "Petrochemistry and Polymer Science / ปิโตรเคมีและวิทยาศาสตร์พอลิเมอร์",
    "Petroleum, Petroleum Technology /  ปิโตรเลียม, เทคโนโลยีปิโตรเลียม",
    "Philosophy and Religion / ปรัชญาและศาสนา",
    "Physics, Applied Physics / ฟิสิกส์, ฟิสิกส์ประยุกต์",
    "Polymer, Polymer Technology / พอลิเมอร์, เทคโนโลยีพอลิเมอร์",
    "Power System Engineering / วิศวกรรมไฟฟ้ากำลัง",
    "Printmaking and Illustration / ภาพพิมพ์และอิลลัสเตชั่น",
    "Product Development Technology / เทคโนโลยีการพัฒนาผลิตภัณฑ์",
    "Production and Tools / การผลิต เครื่องมือและแม่พิมพ์",
    "Production Engineering / วิศวกรรมการผลิต",
    "Production Technology / เทคโนโลยีการผลิต",
    "Psychology Industrial and Organizational Psychology / จิตวิทยา, จิตวิทยาอุตสาหกรรมและองค์การ",
    "Public Administration / รัฐประศาสนศาสตร์",
    "Public Management / บริหารรัฐกิจ",
    "Public Relations / การประชาสัมพันธ์",
    "Pulp and Paper Technology / เทคโนโลยีเยื่อและกระดาษ",
    "Quantitative Economics / เศรษฐศาสตร์ปริมาณวิเคราะห์",
    "Robotics Engineering / วิศวกรรมหุ่นยนต์",
    "Social Administration / สังคมสงเคราะห์ศาสตร์",
    "Sociology and Anthropology / สังคมวิทยาและมานุษยวิทยา",
    "Sociology in Demography / ประชากรศาสตร์",
    "Software Engineering / วิศวกรรมซอฟต์แวร์",
    "Southeast Asian Studies / เอเชียตะวันออกเฉียงใต้ศึกษา",
    "Spanish / ภาษาสเปน",
    "Speech Communication / วาทวิทยา",
    "Statistics, Applied Statistics / สถิติ, สถิติประยุกต์",
    "Supply Chain / ซัพพลายเชนส์",
    "Survey Engineering and Groraphic Information / วิศวกรรมสำรวจและสารสนเทศภูมิศาสตร์",
    "Tax Law / กฎหมายด้านภาษีอากร",
    "Technology Management / การจัดการเทคโนโลยี",
    "Telecommunications Engineering / วิศวกรรมโทรคมนาคม",
    "Textile Chemical / เคมีสิ่งทอ",
    "Thai / ภาษาไทย",
    "Thai Architecture / สถาปัตยกรรมไทย",
    "Tourism and Hotel / การท่องเที่ยวและการโรงแรม",
    "Wood and Paper Products Technology / เทคโนโลยีผลิตภัณฑ์ไม้และกระดาษ",
    "Wood Science and Technology / วิทยาศาสตร์และเทคโนโลยีทางไม้",
    "Zoology, Entomology / สัตววิทยา, กีฏวิทยา",
    "สาขาอื่นๆ โปรดระบุ / Other major, Please specify",
  ],
};

<template>
  <ValidationObserver ref="observer" v-slot="{}" tag="form">
    <v-card>
      <v-card-title class="primary white--text">
        <v-row no-gutters>
          <v-col>Education</v-col>
          <v-col class="text-right">
            <v-btn text fab small dark @click="$emit('onClose')">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <div class="mb-3 mt-3">
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <form-label isRequired>Country / ประเทศ</form-label>
              <ValidationProvider
                :name="'Country / ประเทศ'"
                :rules="'required'"
                key="country"
                v-slot="{ errors, valid }"
              >
                <v-select
                  v-model="education.country"
                  menu-props="auto"
                  :items="countryOptions"
                  label="Country / ประเทศ"
                  solo
                  :error-messages="errors"
                  :success="valid"
                  :hide-details="errors.length == 0"
                ></v-select>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <form-label isRequired>Degree / วุฒิการศึกษา</form-label>
              <ValidationProvider
                :name="'Degree / วุฒิการศึกษา'"
                :rules="'required'"
                key="degree"
                v-slot="{ errors, valid }"
              >
                <v-select
                  v-model="education.degree"
                  menu-props="auto"
                  :items="degreeOptions"
                  label="Degree / วุฒิการศึกษา"
                  solo
                  :error-messages="errors"
                  :success="valid"
                  :hide-details="errors.length == 0"
                ></v-select>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <form-label isRequired>Education Lavel / ระดับการศึกษา</form-label>
              <ValidationProvider
                :name="'Education Lavel / ระดับการศึกษา'"
                :rules="'required'"
                key="degree"
                v-slot="{ errors, valid }"
              >
                <v-select
                  v-model="education.educationlevel"
                  menu-props="auto"
                  :items="educationlevelOptions"
                  label="Education Lavel / ระดับการศึกษา"
                  solo
                  :error-messages="errors"
                  :success="valid"
                  :hide-details="errors.length == 0"
                ></v-select>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <form-label isRequired>Educational Institution / สถาบัน/มหาวิทยาลัย</form-label>
              <ValidationProvider
                :name="'Educational Institution / สถาบัน/มหาวิทยาลัย'"
                :rules="'required'"
                v-slot="{ errors, valid }"
              >
                <v-select
                  v-model="education.institution"
                  menu-props="auto"
                  :items="universityOptions"
                  label="Educational Institution / สถาบัน/มหาวิทยาลัย"
                  solo
                  :error-messages="errors"
                  :success="valid"
                  :hide-details="errors.length == 0"
                ></v-select>
              </ValidationProvider>

              <v-text-field
                class="mt-2"
                v-if="
                  education.institution ==
                    'Others - Please specify in Other Educational Institution'
                "
                label="Others - Please specify in Other Educational Institution"
                solo
                persistent-hint
                v-model="education.otherInstitution"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <form-label isRequired>Faculty / คณะ</form-label>
              <ValidationProvider
                :name="'Faculty / คณะ'"
                :rules="'required'"
                v-slot="{ errors, valid }"
              >
                <v-select
                  v-model="education.faculty"
                  menu-props="auto"
                  :items="facultyOptions"
                  label="Faculty / คณะ"
                  solo
                  :error-messages="errors"
                  :success="valid"
                  :hide-details="errors.length == 0"
                ></v-select>
              </ValidationProvider>

              <v-text-field
                class="mt-2"
                v-if="education.faculty == 'คณะอื่นๆ โปรดระบุ / Others faculty, Please specify'"
                label="คณะอื่นๆ โปรดระบุ / Others faculty, Please specify"
                solo
                persistent-hint
                v-model="education.otherFaculty"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <form-label>Major / สาขาวิชา</form-label>
              <ValidationProvider :name="'Major /  สาขาวิชา'" v-slot="{ errors, valid }">
                <v-select
                  v-model="education.major"
                  menu-props="auto"
                  :items="majorOptions"
                  label="Major /  สาขาวิชา"
                  solo
                  :error-messages="errors"
                  :success="valid"
                  :hide-details="errors.length == 0"
                ></v-select>
              </ValidationProvider>

              <v-text-field
                class="mt-2"
                v-if="education.major == 'สาขาอื่นๆ โปรดระบุ / Other major, Please specify'"
                label="สาขาอื่นๆ โปรดระบุ / Other major, Please specify"
                solo
                persistent-hint
                v-model="education.otherMajor"
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <form-label>GPA., Score, Percentage /เกรดเฉลี่ย, คะแนน</form-label>
              <ValidationProvider
                :name="'GPA., Score, Percentage /เกรดเฉลี่ย, คะแนน'"
                :rules="'min_value:0|max_value:100'"
                v-slot="{ errors, valid }"
              >
                <v-text-field
                  label="GPA., Score, Percentage /เกรดเฉลี่ย, คะแนน"
                  solo
                  persistent-hint
                  v-model="education.gpa"
                  :error-messages="errors"
                  :success="valid"
                  :hide-details="errors.length == 0"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <form-label isRequired>Start Year / ปีเริ่มการศึกษา</form-label>

              <ValidationProvider
                :name="'Start Year / ปีเริ่มการศึกษา'"
                :rules="'required'"
                key="country"
                v-slot="{ errors, valid }"
              >
                <v-select
                  v-model="education.startdate"
                  menu-props="auto"
                  :items="computedYear"
                  label="Start Year / ปีเริ่มการศึกษา"
                  solo
                  :error-messages="errors"
                  :success="valid"
                  :hide-details="errors.length == 0"
                ></v-select>
              </ValidationProvider>
              <!-- <v-dialog
                ref="startDate"
                key="startDate"
                v-model="showStartDate"
                :return-value.sync="education.startdate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <ValidationProvider
                    :name="'Start Date / วันเริ่มทำงาน'"
                    :rules="'required'"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="computedStartDateFormatted"
                      readonly
                      v-on="on"
                      label="DD/MM/YYYY"
                      :error-messages="errors"
                      :success="valid"
                      :hide-details="errors.length == 0"
                      solo
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <v-date-picker v-model="education.startdate" scrollable color="primary">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="showStartDate = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.startDate.save(education.startdate)">OK</v-btn>
                </v-date-picker>
              </v-dialog>-->
            </v-col>
            <v-col cols="12" sm="6">
              <template>
                <form-label>Graduated Year / ปีจบการศึกษา</form-label>

                <ValidationProvider
                  :name="'End Year / ปีสิ้นสุด'"
                  key="country"
                  v-slot="{ errors, valid }"
                >
                  <v-select
                    v-model="education.enddate"
                    menu-props="auto"
                    :items="computedYear"
                    label="End Year / ปีสิ้นสุด"
                    solo
                    :error-messages="errors"
                    :success="valid"
                    :hide-details="errors.length == 0"
                  ></v-select>
                </ValidationProvider>

                <!-- <v-dialog
                  ref="endDate"
                  key="endDate"
                  v-model="showEndDate"
                  :return-value.sync="education.enddate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <ValidationProvider
                      :name="'End Date / วันสิ้นสุด'"
                      :rules="'required'"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        v-model="computedEndDateFormatted"
                        readonly
                        v-on="on"
                        label="DD/MM/YYYY"
                        :error-messages="errors"
                        :success="valid"
                        :hide-details="errors.length == 0"
                        solo
                      ></v-text-field>
                    </ValidationProvider>
                  </template>
                  <v-date-picker v-model="education.enddate" scrollable color="primary">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="showEndDate = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.endDate.save(education.enddate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>-->
              </template>
            </v-col>
          </v-row>
        </div>
        <div class="text-center">
          <v-btn depressed class="ma-1" @click="$emit('onClose')">Cancel</v-btn>
          <v-btn depressed class="primary ma-1" @click="onSave">Save</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </ValidationObserver>
</template>

<script>
import FormLabel from "@/components/FormLabel";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import country from "@/country.js";
import education from "@/education.js";
import moment from "moment";

export default {
  data() {
    return {
      showStartDate: false,
      showEndDate: false,
      education: {
        country: "Thailand",
        educationlevel: null,
        degree: null,
        institution: null,
        otherInstitution: null,
        faculty: null,
        otherFaculty: null,
        major: null,
        otherMajor: null,
        gpa: null,
        startdate: null,
        enddate: null,
      },
      degreeOptions: [
        "Bachelor degree / ปริญญาตรี",
        "Master degree / ปริญญาโท",
        "Ph.D. / ปริญญาเอก",
      ],
      educationlevelOptions: [
        "first-year / ระดับชั้นปีที่ 1",
        "second-year / ระดับชั้นปีที่ 2",
        "third-year / ระดับชั้นปีที่ 3",
        "fourth-year / ระดับชั้นปีที่ 4",
        "fifth-year / ระดับชั้นปีที่ 5",
        "Graduated / จบการศึกษาแล้ว",
      ],
      countryOptions: country.data,
      universityOptions: education.university,
      facultyOptions: education.faculty,
      majorOptions: education.major,
    };
  },
  components: {
    FormLabel,
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    async onSave() {
      var isValid = await this.$refs.observer.validate();

      if (!isValid) {
        this.$nextTick(() => {
          this.$vuetify.goTo(".error--text", {
            duration: 300,
            offset: 0,
            easing: "easeInOutCubic",
            container: ".v-dialog",
          });
        });
        return false;
      }
      if (
        this.education.otherInstitution &&
        this.education.institution.includes("Please specify")
      ) {
        this.education.institution = this.education.otherInstitution;
      }

      if (this.education.otherFaculty && this.education.faculty.includes("Please specify")) {
        this.education.faculty = this.education.otherFaculty;
      }

      if (this.education.otherMajor && this.education.major.includes("Please specify")) {
        this.education.major = this.education.otherMajor;
      }

      this.$emit("onSave", this.education);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  computed: {
    computedYear() {
      var currentYear = parseInt(moment().format("YYYY"));
      var years = [];

      for (let index = currentYear + 10; index >= currentYear - 60; index--) {
        years.push(index);
      }
      return years;
    },
    computedStartDateFormatted() {
      return this.formatDate(this.education.startdate);
    },
    computedEndDateFormatted() {
      return this.formatDate(this.education.enddate);
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div v-if="!loading">
    <template v-if="view">
      <component :is="view"></component>
    </template>
  </div>
</template>

<script>
// @ is an alias to /src
import Register from "./Register";
import Workstyle from "./WorkStyle";
import Share from "./Share";
export default {
  name: "home",
  components: {
    Register,
    Workstyle,
    Share,
  },
  data() {
    return {
      loading: true,
      view: "",
      text: "",
    };
  },
  mounted() {
    if (this.$route.query["liff.state"]) {
      //
    } else if (this.$route.query.view) {
      this.view = ["workstyle", "register", "share"].includes(this.$route.query.view)
        ? this.$route.query.view
        : "";
      this.loading = false;
    } else {
      this.view = "register";
      this.loading = false;
    }
  },
};
</script>

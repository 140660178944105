export default {
    data: [
        'กรุงเทพมหานคร',
      'กระบี่',
      'กาญจนบุรี',
      'กาฬสินธุ์',
      'กำแพงเพชร',
      'ขอนแก่น',
      'จันทบุรี',
      'ฉะเชิงเทรา',
      'ชัยนาท',
      'ชัยภูมิ',
      'ชุมพร',
      'ชลบุรี',
      'เชียงใหม่',
      'เชียงราย',
      'ตรัง',
      'ตราด',
      'ตาก',
      'นครนายก',
      'นครปฐม',
      'นครพนม',
      'นครราชสีมา',
      'นครศรีธรรมราช',
      'นครสวรรค์',
      'นราธิวาส',
      'น่าน',
      'นนทบุรี',
      'บึงกาฬ',
      'บุรีรัมย์',
      'ประจวบคีรีขันธ์',
      'ปทุมธานี',
      'ปราจีนบุรี',
      'ปัตตานี',
      'พะเยา',
      'พระนครศรีอยุธยา',
      'พังงา',
      'พิจิตร',
      'พิษณุโลก',
      'เพชรบุรี',
      'เพชรบูรณ์',
      'แพร่',
      'พัทลุง',
      'ภูเก็ต',
      'มหาสารคาม',
      'มุกดาหาร',
      'แม่ฮ่องสอน',
      'ยโสธร',
      'ยะลา',
      'ร้อยเอ็ด',
      'ระนอง',
      'ระยอง',
      'ราชบุรี',
      'ลพบุรี',
      'ลำปาง',
      'ลำพูน',
      'เลย',
      'ศรีสะเกษ',
      'สกลนคร',
      'สงขลา',
      'สมุทรสาคร',
      'สมุทรปราการ',
      'สมุทรสงคราม',
      'สระแก้ว',
      'สระบุรี',
      'สิงห์บุรี',
      'สุโขทัย',
      'สุพรรณบุรี',
      'สุราษฎร์ธานี',
      'สุรินทร์',
      'สตูล',
      'หนองคาย',
      'หนองบัวลำภู',
      'อำนาจเจริญ',
      'อุดรธานี',
      'อุตรดิตถ์',
      'อุทัยธานี',
      'อุบลราชธานี',
      'อ่างทอง',
    ]
}
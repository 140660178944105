import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify';
import i18n from './i18n'
import "./vee-validate";
import VueSweetalert2 from 'vue-sweetalert2';
import VueKonva from 'vue-konva'

Vue.use(VueKonva)
Vue.prototype.$liff = window.liff

 
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
 
Vue.use(VueSweetalert2);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')

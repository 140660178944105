<template>
  <div class="rsu">
    <div v-resize="onResize">
      <div id="appContainer" class="ma-auto" style="max-width: 375px">
        <audio ref="backgroundSound" autoplay muted allow="autoplay">
          <source :src="`${publicPath}sound/Summers.wav`" type="audio/wav" />
        </audio>
        <audio ref="punchSound" muted>
          <source :src="`${publicPath}sound/Body_Punch_Series.mp3`" type="audio/mp3" />
        </audio>

        <div class="screen_measure"></div>
        <div v-show="currentState == 'startscreen'">
          <!-- <div
            class="pa-2"
            :style="{'background-image': `url(${publicPath}img/startscreenbg.png)`}"
            style="position: relative;min-height: 100vh;  width: 100%;background-size: cover; background-position: top;"
          >
            <img
              class="bounce"
              :src="`${publicPath}img/Scg_logo.png`"
              style="max-width: 100%; width: 100%"
            />
            <div>
              <img
                class="breathing"
                :src="`${publicPath}img/characters/01_CH-SCG_Explorer.png`"
                style="width: 100%"
              />
            </div>

            <div
              class="text-center"
              style="position: absolute; bottom: 25px; left: 0; right: 0;width: 100%;"
            >
              <button @click="currentState = 'selectcharacter'">
                <img :src="`${publicPath}img/btn_play.png`" style="max-width: 150px; width: 100%" />
              </button>
            </div>
          </div>-->
          <div v-show="loading" style="height: 100vh; background: white">
            <v-row class="text-center" align="center" justify="center" style="height: 100%">
              <v-col>
                <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
              </v-col>
            </v-row>
          </div>
          <div v-show="!loading">
            <div
              style="
                min-height: 100vh;
                background: rgb(83, 184, 114);
                background: linear-gradient(
                  90deg,
                  rgba(83, 184, 114, 1) 0%,
                  rgba(69, 203, 117, 1) 51%,
                  rgba(83, 184, 114, 1) 100%
                );
              "
            >
              <v-stage
                ref="startscreen_stage"
                :config="{
                  height: 480,
                  width: 320,
                }"
                :key="'startscreen_stage'"
              >
                <v-layer>
                  <v-image
                    :config="{
                      height: 480,
                      width: 320,
                      image: assetStartScreens.startscreen,
                    }"
                  ></v-image>

                  <v-image
                    :config="{
                      width: 280,
                      height: 200,
                      offset: {
                        x: 140,
                        y: 100,
                      },
                      x: 160,
                      y: 100,
                      image: assetStartScreens.startlogo,
                    }"
                  ></v-image>

                  <v-image
                    :config="{
                      width: 230,
                      height: 230,
                      offset: {
                        x: 115,
                        y: 115,
                      },
                      x: 160,
                      y: 300,
                      image: assetStartScreens.startcharacter,
                    }"
                  ></v-image>

                  <v-image
                    :config="{
                      width: 100,
                      height: 50,
                      offset: {
                        x: 50,
                        y: 25,
                      },
                      x: 160,
                      y: 430,
                      image: assetStartScreens.startbutton,
                    }"
                    @click="currentState = 'selectcharacter'"
                    @tap="currentState = 'selectcharacter'"
                  ></v-image>
                </v-layer>
              </v-stage>
            </div>
          </div>
        </div>
        <div v-show="currentState == 'selectcharacter'">
          <div
            style="
              min-height: 100vh;
              background: rgb(255, 124, 124);
              background: linear-gradient(
                180deg,
                rgba(255, 124, 124, 1) 0%,
                rgba(255, 159, 108, 1) 100%
              );
            "
          >
            <v-container>
              <h2 class="text-center white--text">คุณคิดว่า คุณมี work style แบบไหน?</h2>
              <v-row>
                <template v-for="job in characters">
                  <v-col cols="6" :key="job.id">
                    <div style="position: relative">
                      <div
                        style="
                          position: absolute;
                          bottom: 0;
                          top: 0;
                          right: 0;
                          left: 0;
                          opacity: 0.17;
                          background: white;
                          border-radius: 50%;
                        "
                      ></div>
                      <img
                        class="breathing"
                        :src="`${publicPath}img/characters/${job.img}`"
                        style="width: 100%; position: relative; z-index: 2"
                      />
                      <div
                        class="text-center"
                        style="position: absolute; bottom: 0; left: 0; right: 0; z-index: 2"
                      >
                        <v-btn rounded color="primary" depressed @click="selectCharacter(job)"
                          >เลือก {{ job.name }}</v-btn
                        >
                      </div>
                    </div>
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </div>
        </div>
        <div v-show="currentState == 'battle'">
          <div
            style="height: 100vh; max-height: 100vh; background: #142328; position: relative"
            v-if="currentCharacter"
          >
            <div v-show="loading" style="height: 100%; background: white">
              <v-row class="text-center" align="center" justify="center" style="height: 100%">
                <v-col>
                  <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </div>
            <div v-show="!loading">
              <v-stage ref="stage" :config="configBattleKonva" :key="'stage'">
                <v-layer>
                  <v-image
                    :config="{ x: 0, y: 0, width: 320, height: 350, image: assets.background }"
                  ></v-image>

                  <v-image
                    ref="monster"
                    v-if="assets.monster"
                    :config="{
                      x: 260,
                      y: 140,
                      width: 100,
                      height: 60,
                      image: assets.monster,
                      offsetX: 50,
                      offsetY: 30,
                    }"
                  ></v-image>

                  <v-image
                    ref="character"
                    v-if="assets.character"
                    :config="{
                      x: 90,
                      y: 80,
                      width: 250,
                      height: 250,
                      image: assets.character,
                      scaleX: currentCharacter.name.toLowerCase() == 'mentor' ? 1 : -1,
                      offsetX: 125,
                    }"
                  ></v-image>

                  <v-sprite ref="bombsprite" :config="bombSpriteConfig" />
                </v-layer>
              </v-stage>
              <div class="pa-2" style="position: absolute; top: 10px; right: 10px">
                <div
                  class="pa-2"
                  style="width: 175px; height: 30px; border-radius: 30px; background: white"
                >
                  <div style="background: #ff6c6c; height: 100%; border-radius: 30px">
                    <div
                      style="background: #80ff80; height: 100%; border-radius: 30px"
                      :style="{ width: ((8 - currentQuestion) * 100) / 8 + '%' }"
                    ></div>
                  </div>
                </div>
              </div>
              <div
                class="pa-2"
                style="position: absolute; bottom: 0; left: 0; right: 0; background: #8d2e2d"
              >
                <div
                  v-if="loadingQuestion || currentQuestion > 7"
                  style="min-height: 200px; position: relative"
                >
                  <v-row align="center" justify="center" style="hight: 100%">
                    <v-col class="text-center">
                      <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </v-col>
                  </v-row>
                </div>
                <template v-else-if="currentQuestion <= 7">
                  <div class="white--text px-3">โปรดเลือกคำตอบที่ตรงกับตนเองที่สุด</div>
                  <div class="px-4 py-2 white--text btn-game" @click="nextQuestion('a')">
                    <v-row style="flex-wrap: nowrap">
                      <v-col class="flex-grow-0">
                        <h2>A.</h2>
                      </v-col>
                      <v-col class="flex-grow-1 pl-0 pr-2 py-1">{{
                        currentCharacter.questions[currentQuestion].a
                      }}</v-col>
                    </v-row>
                  </div>
                  <div
                    class="px-4 py-0 white--text btn-game"
                    style="background: #fc5c5a; border-radius: 5px"
                    @click="nextQuestion('b')"
                  >
                    <v-row style="flex-wrap: nowrap">
                      <v-col class="flex-grow-0">
                        <h2>B.</h2>
                      </v-col>
                      <v-col class="flex-grow-1 pl-0 pr-2 py-1">{{
                        currentCharacter.questions[currentQuestion].b
                      }}</v-col>
                    </v-row>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <template v-if="currentState == 'result'">
          <div
            style="
              min-height: 100vh;
              overflow: hidden;
              background: rgb(255, 124, 124);
              background: linear-gradient(
                180deg,
                rgba(255, 124, 124, 1) 0%,
                rgba(255, 159, 108, 1) 100%
              );
            "
          >
            <v-container>
              <div
                class="text-center"
                style="
                  position: relative;
                  top: 50px;
                  left: 0;
                  right: 0;
                  margin-top: -50px;
                  z-index: 2;
                "
              >
                <img
                  class="spin"
                  :src="`${publicPath}img/sunburst.png`"
                  style="
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    margin-left: -290px;
                    margin-top: -290px;
                  "
                />
                <img
                  class="breathing ma-auto"
                  :src="`${publicPath}img/result_characters/${currentCharacter.img}`"
                  style="width: 100%; max-width: 250px"
                />
                <div style="position: absolute; bottom: -20px; left: 0; right: 0">
                  <div class="ribbon">
                    <div class="ribbon-content white--text">{{ currentCharacter.name }}</div>
                  </div>
                </div>
              </div>
              <div class="elevation-4" style="border-radius: 10px; background: white">
                <div style="height: 90px"></div>
                <div class="pa-2">
                  <div class="text-center">ลักษณะเด่นของ Work Style นี้</div>
                  <div class="text-center primary--text">{{ currentCharacter.character }}</div>
                </div>
                <v-divider />
                <div class="pa-2">
                  <div class="text-center">คุณมีลักษณะตรงกับ Work Style นี้</div>
                  <h1
                    class="text-center primary--text rsu"
                    style="
                      font-size: 3.75rem !important;
                      font-weight: 300;
                      line-height: 3.75rem;
                      letter-spacing: -0.0083333333em !important;
                    "
                  >
                    {{ matchingPercent }}%
                  </h1>
                </div>
                <v-divider />
                <div class="pa-2 pb-5">
                  <div class="text-center">ลักษณะอื่นๆของ Work Style นี้</div>
                  <div class="text-center grey--text">
                    <div v-for="(sub, index) in currentCharacter.subcharacter" :key="index">
                      - {{ sub }}
                    </div>
                  </div>
                </div>
              </div>

              <button class="btn-fb mx-0 py-3 white--text" style="width: 100%" @click="shareFB()">
                <v-icon class="mr-2" color="white">mdi-facebook</v-icon>แชร์ไปให้เพื่อน
              </button>

              <button
                class="btn-game mx-0 py-3 white--text"
                style="width: 100%"
                @click="currentState = 'startscreen'"
              >
                <v-icon class="mr-2" color="white">mdi-autorenew</v-icon>ลองเล่นใหม่
              </button>
            </v-container>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import json_characters from "../json/characters.json";
import axios from "axios";
export default {
  data() {
    return {
      userid: null,
      publicPath: process.env.BASE_URL,
      currentState: "startscreen",
      totalscore: 0,
      currentMonster: {
        name: "",
        img: "Slime.png",
      },
      currentBackground: {
        name: "cave",
        img: "cave.png",
      },
      currentCharacter: null,
      currentQuestion: 0,
      characters: json_characters,
      loading: true,
      loadingQuestion: false,
      configBattleKonva: {
        width: 375,
        height: 480,
      },
      monsters: [
        {
          name: "",
          img: "Slime.png",
        },
      ],
      assets: {},
      assetStartScreens: {},
      answers: [null, null, null, null, null, null, null, null],
      timestamp: moment().format("x"),
      animIdleCharacter: null,
      animIdleMonster: null,
      animMoveCharacter: null,
      bombSpriteConfig: {
        animation: "bomb",
        frameRate: 7,
        frameIndex: 0,
        opacity: 0,
        x: 260,
        y: 140,
        offset: { x: 75.5, y: 78 },
        animations: {
          bomb: [
            0, 0, 156, 151, 156, 0, 156, 151, 312, 0, 156, 151, 0, 151, 156, 151, 156, 151, 156,
            151, 312, 151, 156, 151, 0, 302, 156, 151, 156, 302, 156, 151,
          ],
        },
      },
      windowSize: { x: 0, y: 0 },
      configStartState: {
        width: 320,
        height: 480,
      },
      width: 0,
      scale: 1,
    };
  },
  computed: {
    matchingPercent() {
      return this.totalscore == 0 ? this.totalscore : parseInt((this.totalscore * 100) / 8);
    },
  },
  async mounted() {
    // this.selectCharacter(this.characters[0]);
    if (!this.$liff.isLoggedIn()) {
      // set `redirectUri` to redirect the user to a URL other than the front page of your this.$liff app.
      // eslint-disable-next-line
      console.log(this.$router.fullPath);
      this.$liff.login({
        redirectUri: this.$router.fullPath,
      });
    } else {
      var profile = await this.$liff.getProfile();
      this.userid = profile.userId;
    }
    await this.preloadStartScreen();
    await this.$nextTick();
    this.onResize();
    // eslint-disable-next-line

    this.$refs.backgroundSound.loop = true;
    this.$refs.backgroundSound.muted = false;
    this.$refs.backgroundSound.volume = 0.1;
    await this.$refs.backgroundSound.play();
  },
  methods: {
    async playPunchSound() {
      await this.$refs.punchSound.pause();
      this.$refs.punchSound.muted = false;
      // this.$refs.backgroundSound.volume = 0.5;
      this.$refs.punchSound.currentTime = 0;
      await this.$refs.punchSound.play();
      // eslint-disable-next-line

      setTimeout(async () => {
        // eslint-disable-next-line

        await this.$refs.punchSound.pause();
      }, 200);
    },
    fitStageIntoParentContainer(stage) {
      var container = document.querySelector(".screen_measure");

      // now we need to fit stage into parent
      var containerWidth = container.offsetWidth;
      var stageWidth = this.configStartState.width;
      var stageHeight = this.configStartState.height;
      // to do this we need to scale the stage
      var scale = containerWidth / stageWidth;
      // eslint-disable-next-line
      // console.log(containerWidth, scale);

      stage.width(stageWidth * scale);
      stage.height(stageHeight * scale);
      stage.scale({ x: scale, y: scale });
      stage.draw();
    },
    onResize() {
      this.width = document.getElementById("appContainer").clientWidth;
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };

      this.fitStageIntoParentContainer(this.$refs.startscreen_stage.getNode());
      if (this.$refs.stage) {
        this.fitStageIntoParentContainer(this.$refs.stage.getNode());
      }
    },
    async nextQuestion(answer) {
      this.loadingQuestion = true;
      if (this.currentQuestion <= 7) {
        this.totalscore +=
          this.currentCharacter.questions[this.currentQuestion].answer == answer ? 1 : 0;

        this.$set(this.answers, this.currentQuestion, answer);
      }
      if (this.currentQuestion <= 7) {
        this.currentQuestion += 1;
        this.animIdleCharacter.stop();
        this.animIdleMonster.stop();

        var character = this.$refs.character.getNode();
        var monster = this.$refs.monster.getNode();

        let characterPromise = this.loadMoveAnimation(character);
        let monsterPromise = this.loadAttackedAnimation(monster);

        const bombnode = this.$refs.bombsprite.getNode();
        bombnode.setOpacity(1);
        bombnode.start();
        setTimeout(() => {
          bombnode.stop();
          bombnode.setFrameIndex(0);
          bombnode.setOpacity(0);
        }, 1000);

        var soundPromise = this.playPunchSound();
        await characterPromise;
        await monsterPromise;
        await soundPromise;

        this.animIdleCharacter.start();
        this.animIdleMonster.start();
      }

      if (this.currentQuestion == 8) {
        await setTimeout(async () => {
          this.loading = true;
          await this.saveStat();
          setTimeout(() => {
            this.currentState = "result";
            this.loading = false;
          }, 2000);
        }, 1000);
      }
      this.loadingQuestion = false;
    },
    async selectCharacter(character) {
      this.currentCharacter = character;
      this.currentState = "battle";
      this.currentQuestion = 0;
      this.totalscore = 0;
      this.currentMonster = this.monsters[0];
      await this.$nextTick();
      await this.preload();
      await this.$nextTick();
      this.onResize();
    },
    async preloadStartScreen() {
      let assetsPromise = [];
      this.loading = true;
      assetsPromise.push(this.loadAssets(`${this.publicPath}img/Scg_logo.png`, "startlogo"));

      assetsPromise.push(
        this.loadAssets(`${this.publicPath}img/characters/01_CH-SCG_Explorer.png`, "startcharacter")
      );

      assetsPromise.push(this.loadAssets(`${this.publicPath}img/startscreenbg.png`, "startscreen"));

      assetsPromise.push(this.loadAssets(`${this.publicPath}img/btn_play.png`, "startbutton"));

      if (assetsPromise.length > 0) {
        let assets = await Promise.all(assetsPromise);
        this.assetStartScreens = {};
        for (let index = 0; index < assets.length; index++) {
          this.$set(this.assetStartScreens, assets[index].type, assets[index].img);
        }
      }
      this.loading = false;
      await this.$nextTick();

      // var character = this.$refs.character.getNode();
      // this.animIdleCharacter = this.loadIdleAnimation(character);
      // this.animIdleCharacter.start();

      this.timestamp = moment().format("x");
    },
    async preload() {
      let assetsPromise = [];
      this.loading = true;
      if (this.currentCharacter) {
        assetsPromise.push(
          this.loadAssets(
            `${this.publicPath}img/characters/${this.currentCharacter.img}`,
            "character"
          )
        );
      }

      if (this.currentMonster) {
        assetsPromise.push(
          this.loadAssets(`${this.publicPath}img/monsters/${this.currentMonster.img}`, "monster")
        );
      }

      if (this.currentBackground) {
        assetsPromise.push(
          this.loadAssets(
            `${this.publicPath}img/backgrounds/${this.currentBackground.img}`,
            "background"
          )
        );
      }

      assetsPromise.push(this.loadAssets(`${this.publicPath}img/effects/bomb.png`, "bomb"));

      if (assetsPromise.length > 0) {
        let assets = await Promise.all(assetsPromise);
        this.assets = {};
        for (let index = 0; index < assets.length; index++) {
          this.$set(this.assets, assets[index].type, assets[index].img);
        }
      }
      this.loading = false;
      await this.$nextTick();

      var character = this.$refs.character.getNode();
      this.animIdleCharacter = this.loadIdleAnimation(character);
      this.animIdleCharacter.start();

      var monster = this.$refs.monster.getNode();
      this.animIdleMonster = this.loadIdleAnimation(monster);
      this.animIdleMonster.start();

      this.timestamp = moment().format("x");

      this.$set(this.bombSpriteConfig, "image", this.assets["bomb"]);
    },
    loadAssets(src, type) {
      return new Promise((resolve, reject) => {
        const image = new window.Image();
        image.src = src;
        image.onload = () => {
          resolve({ img: image, type: type });
        };
        image.onerror = (e) => {
          reject(new Error(e));
        };
      });
    },
    loadIdleAnimation(node) {
      // const vm = this;
      const amplitude = 5;
      const period = 2000;
      var nodeHeight = node.getHeight();
      var nodeY = node.getY();
      // eslint-disable-next-line
      return new Konva.Animation(function (frame) {
        // var time = frame.time,
        //   timeDiff = frame.timeDiff,
        //   frameRate = frame.frameRate;
        // eslint-disable-next-line
        // console.log(time, timeDiff, frameRate);
        var newHeight = amplitude * Math.sin((frame.time * 2 * Math.PI) / period) + nodeHeight;

        node.setHeight(newHeight);
        node.setY(nodeY - (newHeight - nodeHeight));
      }, node.getLayer());
    },
    loadMoveAnimation(node) {
      // var velocity = 50;

      // var vm = this;

      return new Promise((resolve) => {
        /* eslint-disable */

        var runForward = new Konva.Tween({
          node: node,
          x: 160,
          duration: 0.1,
          easing: Konva.Easings.EaseInOut,
          onFinish: function () {
            var runBackward = new Konva.Tween({
              node: node,
              x: 111,
              duration: 0.1,
              easing: Konva.Easings.EaseInOut,
              onFinish: function () {
                resolve();
              },
            });

            runBackward.play();
          },
        });
        runForward.play();

        /* eslint-enable */
      });
    },
    loadAttackedAnimation(node) {
      /* eslint-disable */
      var x = node.getX();
      var y = node.getY();

      return new Promise((resolve) => {
        var attackedStart = new Konva.Tween({
          node: node,
          duration: 0.2,
          opacity: 0.5,
          x: x,
          y: y,
          scaleX: 1.5,
          onFinish: function () {
            var attackedEnd = new Konva.Tween({
              node: node,
              duration: 0.2,

              opacity: 1,
              x: x,
              y: y,
              scaleX: 1,
              onFinish: function () {
                resolve();
              },
            });
            attackedEnd.play();
          },
        });
        attackedStart.play();
      });
      /* eslint-enable */
    },
    getHostname() {
      return window.location.protocol + "//" + window.location.host;
    },
    shareFB() {
      // https://share.scgroadshow.com/share?job=planner&percent=52
      var url =
        "https://m.facebook.com/dialog/share?app_id=465788463995277&display=popup&href=" +
        encodeURIComponent(
          "https://share.scgroadshow.com/share?job=" +
            this.currentCharacter.name +
            "&percent=" +
            this.matchingPercent
        ) +
        "&hashtag=" +
        encodeURIComponent("#SCGCareer") +
        "&redirect_uri=" +
        encodeURIComponent("https://www.facebook.com");
      window.open(
        url,
        "Facebook Share",
        "height=450, width=550, top=" +
          (screen.height / 2 - 275) +
          ", left=" +
          (screen.width / 2 - 225) +
          ", toolbar=0, location=0, menubar=0, directories=0, scrollbars=0"
      );
    },
    async saveStat() {
      var data = {
        userid: this.userid,
        workstyle: this.currentCharacter.name.toLowerCase(),
        total: this.totalscore,
        answer: this.answers.join(","),
      };
      try {
        var response = await axios.post(
          "https://api-enterprise.zwiz.app/scgcareersadventureresult",
          data,
          {
            headers: { AUTHORIZATION: "Bearer xxx xxx" },
          }
        );
        if (response && response.data.responsecode == 200) {
          //
          return true;
        } else {
          return false;
        }
      } catch (error) {
        //
      }
    },
  },
  beforeDestroy() {
    if (this.animIdleCharacter) {
      this.animIdleCharacter.stop();
    }
    if (this.animIdleMonster) {
      this.animIdleMonster.stop();
    }
    if (this.animMoveCharacter) {
      this.animMoveCharacter.stop();
    }
  },
};
</script>

<style lang="scss" scoped>
.ribbon {
  font-size: 22px !important;
  position: relative;
  background: #ff3636;
  color: #fff;
  text-align: center;
  max-width: 250px;
  border-radius: 5px;
  padding: 0.5em 2em; /* Adjust to suit */
  //   margin: 2em auto 3em;
  margin: auto; /* Based on 24px vertical rhythm. 48px bottom margin - normally 24 but the ribbon 'graphics' take up 24px themselves so we double it. */
}
.ribbon:before,
.ribbon:after {
  content: "";
  position: absolute;
  display: block;
  bottom: -0.5em;
  border: 1em solid #ff4e4e;
  z-index: -1;
}
.ribbon:before {
  left: -1.5em;
  border-right-width: 1.5em;
  border-left-color: transparent;
}
.ribbon:after {
  right: -1.5em;
  border-left-width: 1.5em;
  border-right-color: transparent;
}
.ribbon .ribbon-content:before,
.ribbon .ribbon-content:after {
  content: "";
  position: absolute;
  display: block;
  border-style: solid;
  border-color: #d23333 transparent transparent transparent;
  bottom: -0.5em;
}
.ribbon .ribbon-content:before {
  left: 0;
  border-width: 0.5em 0 0 1em;
}
.ribbon .ribbon-content:after {
  right: 0;
  border-width: 0.5em 1em 0 0;
}

.btn-game {
  margin: 10px;
  box-shadow: 0px 4px #ef4442;
  background: #fc5c5a;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    transform: translateY(2px);
    box-shadow: 0px 2px #ef4442;
  }

  &:active {
    background: #a5312f;
    transform: translateY(2px);
    box-shadow: 0px 2px #a02320;
  }
}

.btn-fb {
  margin: 10px;
  box-shadow: 0px 4px rgb(57, 91, 160);
  background: #4267b2;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    transform: translateY(2px);
    box-shadow: 0px 2px rgb(57, 91, 160);
  }

  &:active {
    background: rgb(55, 89, 156);
    transform: translateY(2px);
    box-shadow: 0px 2px rgb(48, 78, 139);
  }
}

.bounce {
  -webkit-animation: bounce-y-animation 3s ease-in-out infinite; /* Safari 4+ */
  -moz-animation: bounce-y-animation 3s ease-in-out infinite; /* Fx 5+ */
  -o-animation: bounce-y-animation 3s ease-in-out infinite; /* Opera 12+ */
  animation: bounce-y-animation 3s ease-in-out infinite; /* IE 10+, Fx 29+ */
}

.breathing {
  -webkit-animation: breathing-animation 5s infinite; /* Safari 4+ */
  -moz-animation: breathing-animation 5s infinite; /* Fx 5+ */
  -o-animation: breathing-animation 5s infinite; /* Opera 12+ */
  animation: breathing-animation 5s infinite; /* IE 10+, Fx 29+ */
  transform-origin: bottom center;
}

.spin {
  -webkit-animation: spin-animation 10s infinite; /* Safari 4+ */
  -moz-animation: spin-animation 10s infinite; /* Fx 5+ */
  -o-animation: spin-animation 10s infinite; /* Opera 12+ */
  animation: spin-animation 10s infinite; /* IE 10+, Fx 29+ */
  animation-timing-function: linear;
}

@-webkit-keyframes bounce-y-animation {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-moz-keyframes bounce-y-animation {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-o-keyframes bounce-y-animation {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes bounce-y-animation {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
}

//breathing
@-webkit-keyframes breathing-animation {
  0% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.95);
  }
  100% {
    transform: scaleY(1);
  }
}
@-moz-keyframes breathing-animation {
  0% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.95);
  }
  100% {
    transform: scaleY(1);
  }
}
@-o-keyframes breathing-animation {
  0% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.95);
  }
  100% {
    transform: scaleY(1);
  }
}
@keyframes breathing-animation {
  0% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.95);
  }
  100% {
    transform: scaleY(1);
  }
}

// spin
@-webkit-keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-moz-keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

export  default {
    data: [
      {
        value: 'afghan',text: 'Afghan'
      },
      {
        value: 'albanian',text: 'Albanian'
      },
      {
        value: 'algerian',text: 'Algerian'
      },
      {
        value: 'american',text: 'American'
      },
      {
        value: 'andorran',text: 'Andorran'
      },
      {
        value: 'angolan',text: 'Angolan'
      },
      {
        value: 'antiguans',text: 'Antiguans'
      },
      {
        value: 'argentinean',text: 'Argentinean'
      },
      {
        value: 'armenian',text: 'Armenian'
      },
      {
        value: 'australian',text: 'Australian'
      },
      {
        value: 'austrian',text: 'Austrian'
      },
      {
        value: 'azerbaijani',text: 'Azerbaijani'
      },
      {
        value: 'bahamian',text: 'Bahamian'
      },
      {
        value: 'bahraini',text: 'Bahraini'
      },
      {
        value: 'bangladeshi',text: 'Bangladeshi'
      },
      {
        value: 'barbadian',text: 'Barbadian'
      },
      {
        value: 'barbudans',text: 'Barbudans'
      },
      {
        value: 'batswana',text: 'Batswana'
      },
      {
        value: 'belarusian',text: 'Belarusian'
      },
      {
        value: 'belgian', text: 'Belgian'
      },
      {
        value: 'belizean',text: 'Belizean'
      },
      {
        value: 'beninese',text: 'Beninese'
      },
      {
        value: 'bhutanese',text: 'Bhutanese'
      },
      {
        value: 'bolivian',text: 'Bolivian'
      },
      {
        value: 'bosnian',text: 'Bosnian'
      },
      {
        value: 'brazilian',text: 'Brazilian'
      },
      {
        value: 'british',text: 'British'
      },
      {
        value: 'bruneian',text: 'Bruneian'
      },
      {
        value: 'bulgarian',text: 'Bulgarian'
      },
      {
        value: 'burkinabe',text: 'Burkinabe'
      },
      {
        value: 'burmese',text: 'Burmese'
      },
      {
        value: 'burundian',text: 'Burundian'
      },
      {
        value: 'cambodian',text: 'Cambodian'
      },
      {
        value: 'cameroonian',text: 'Cameroonian'
      },
      {
        value: 'canadian',text: 'Canadian'
      },
      {
        value: 'cape verdean',text: 'Cape Verdean'
      },
      {
        value: 'central african',text: 'Central African'
      },
      {
        value: 'chadian',text: 'Chadian'
      },
      {
        value: 'chilean',text: 'Chilean'
      },
      {
        value: 'chinese',text: 'Chinese'
      },
      {
        value: 'colombian',text: 'Colombian'
      },
      {
        value: 'comoran',text: 'Comoran'
      },
      {
        value: 'congolese',text: 'Congolese'
      },
      {
        value: 'costa rican',text: 'Costa Rican'
      },
      {
        value: 'croatian',text: 'Croatian'
      },
      {
        value: 'cuban',text: 'Cuban'
      },
      {
        value: 'cypriot',text: 'Cypriot'
      },
      {
        value: 'czech',text: 'Czech'
      },
      {
        value: 'danish',text: 'Danish'
      },
      {
        value: 'djibouti',text: 'Djibouti'
      },
      {
        value: 'dominican',text: 'Dominican'
      },
      {
        value: 'dutch',text: 'Dutch'
      },
      {
        value: 'east timorese',text: 'East Timorese'
      },
      {
        value: 'ecuadorean',text: 'Ecuadorean'
      },
      {
        value: 'egyptian',text: 'Egyptian'
      },
      {
        value: 'emirian',text: 'Emirian'
      },
      {
        value: 'equatorial guinean',text: 'Equatorial Guinean'
      },
      {
        value: 'eritrean',text: 'Eritrean'
      },
      {
        value: 'estonian',text: 'Estonian'
      },
      {
        value: 'ethiopian',text: 'Ethiopian'
      },
      {
        value: 'fijian',text: 'Fijian'
      },
      {
        value: 'filipino',text: 'Filipino'
      },
      {
        value: 'finnish',text: 'Finnish'
      },
      {
        value: 'french',text: 'French'
      },
      {
        value: 'gabonese',text: 'Gabonese'
      },
      {
        value: 'gambian',text: 'Gambian'
      },
      {
        value: 'georgian',text: 'Georgian'
      },
      {
        value: 'german',text: 'German'
      },
      {
        value: 'ghanaian',text: 'Ghanaian'
      },
      {
        value: 'greek',text: 'Greek'
      },
      {
        value: 'grenadian',text: 'Grenadian'
      },
      {
        value: 'guatemalan',text: 'Guatemalan'
      },
      {
        value: 'guinea-bissauan',text: 'Guinea-Bissauan'
      },
      {
        value: 'guinean',text: 'Guinean'
      },
      {
        value: 'guyanese',text: 'Guyanese'
      },
      {
        value: 'haitian',text: 'Haitian'
      },
      {
        value: 'herzegovinian',text: 'Herzegovinian'
      },
      {
        value: 'honduran',text: 'Honduran'
      },
      {
        value: 'hungarian',text: 'Hungarian'
      },
      {
        value: 'icelander',text: 'Icelander'
      },
      {
        value: 'indian',text: 'Indian'
      },
      {
        value: 'indonesian',text: 'Indonesian'
      },
      {
        value: 'iranian',text: 'Iranian'
      },
      {
        value: 'iraqi',text: 'Iraqi'
      },
      {
        value: 'irish',text: 'Irish'
      },
      {
        value: 'israeli',text: 'Israeli'
      },
      {
        value: 'italian',text: 'Italian'
      },
      {
        value: 'ivorian',text: 'Ivorian'
      },
      {
        value: 'jamaican',text: 'Jamaican'
      },
      {
        value: 'japanese',text: 'Japanese'
      },
      {
        value: 'jordanian',text: 'Jordanian'
      },
      {
        value: 'kazakhstani',text: 'Kazakhstani'
      },
      {
        value: 'kenyan',text: 'Kenyan'
      },
      {
        value: 'kittian and nevisian',text: 'Kittian and Nevisian'
      },
      {
        value: 'kuwaiti',text: 'Kuwaiti'
      },
      {
        value: 'kyrgyz',text: 'Kyrgyz'
      },
      {
        value: 'laotian',text: 'Laotian'
      },
      {
        value: 'latvian',text: 'Latvian'
      },
      {
        value: 'lebanese',text: 'Lebanese'
      },
      {
        value: 'liberian',text: 'Liberian'
      },
      {
        value: 'libyan',text: 'Libyan'
      },
      {
        value: 'liechtensteiner',text: 'Liechtensteiner'
      },
      {
        value: 'lithuanian',text: 'Lithuanian'
      },
      {
        value: 'luxembourger',text: 'Luxembourger'
      },
      {
        value: 'macedonian',text: 'Macedonian'
      },
      {
        value: 'malagasy',text: 'Malagasy'
      },
      {
        value: 'malawian',text: 'Malawian'
      },
      {
        value: 'malaysian',text: 'Malaysian'
      },
      {
        value: 'maldivan',text: 'Maldivan'
      },
      {
        value: 'malian',text: 'Malian'
      },
      {
        value: 'maltese',text: 'Maltese'
      },
      {
        value: 'marshallese',text: 'Marshallese'
      },
      {
        value: 'mauritanian',text: 'Mauritanian'
      },
      {
        value: 'mauritian',text: 'Mauritian'
      },
      {
        value: 'mexican',text: 'Mexican'
      },
      {
        value: 'micronesian',text: 'Micronesian'
      },
      {
        value: 'moldovan',text: 'Moldovan'
      },
      {
        value: 'monacan',text: 'Monacan'
      },
      {
        value: 'mongolian',text: 'Mongolian'
      },
      {
        value: 'moroccan',text: 'Moroccan'
      },
      {
        value: 'mosotho',text: 'Mosotho'
      },
      {
        value: 'motswana',text: 'Motswana'
      },
      {
        value: 'mozambican',text: 'Mozambican'
      },
      {
        value: 'namibian',text: 'Namibian'
      },
      {
        value: 'nauruan',text: 'Nauruan'
      },
      {
        value: 'nepalese',text: 'Nepalese'
      },
      {
        value: 'new zealander',text: 'New Zealander'
      },
      {
        value: 'ni-vanuatu',text: 'Ni-Vanuatu'
      },
      {
        value: 'nicaraguan',text: 'Nicaraguan'
      },
      {
        value: 'nigerien',text: 'Nigerien'
      },
      {
        value: 'north korean',text: 'North Korean'
      },
      {
        value: 'northern irish',text: 'Northern Irish'
      },
      {
        value: 'norwegian',text: 'Norwegian'
      },
      {
        value: 'omani',text: 'Omani'
      },
      {
        value: 'pakistani',text: 'Pakistani'
      },
      {
        value: 'palauan',text: 'Palauan'
      },
      {
        value: 'panamanian',text: 'Panamanian'
      },
      {
        value: 'papua new guinean',text: 'Papua New Guinean'
      },
      {
        value: 'paraguayan',text: 'Paraguayan'
      },
      {
        value: 'peruvian',text: 'Peruvian'
      },
      {
        value: 'polish',text: 'Polish'
      },
      {
        value: 'portuguese',text: 'Portuguese'
      },
      {
        value: 'qatari',text: 'Qatari'
      },
      {
        value: 'romanian',text: 'Romanian'
      },
      {
        value: 'russian',text: 'Russian'
      },
      {
        value: 'rwandan',text: 'Rwandan'
      },
      {
        value: 'saint lucian',text: 'Saint Lucian'
      },
      {
        value: 'salvadoran',text: 'Salvadoran'
      },
      {
        value: 'samoan',text: 'Samoan'
      },
      {
        value: 'san marinese',text: 'San Marinese'
      },
      {
        value: 'sao tomean',text: 'Sao Tomean'
      },
      {
        value: 'saudi',text: 'Saudi'
      },
      {
        value: 'scottish',text: 'Scottish'
      },
      {
        value: 'senegalese',text: 'Senegalese'
      },
      {
        value: 'serbian',text: 'Serbian'
      },
      {
        value: 'seychellois',text: 'Seychellois'
      },
      {
        value: 'sierra leonean',text: 'Sierra Leonean'
      },
      {
        value: 'singaporean',text: 'Singaporean'
      },
      {
        value: 'slovakian',text: 'Slovakian'
      },
      {
        value: 'slovenian',text: 'Slovenian'
      },
      {
        value: 'solomon islander',text: 'Solomon Islander'
      },
      {
        value: 'somali',text: 'Somali'
      },
      {
        value: 'south african',text: 'South African'
      },
      {
        value: 'south korean',text: 'South Korean'
      },
      {
        value: 'spanish',text: 'Spanish'
      },
      {
        value: 'sri lankan',text: 'Sri Lankan'
      },
      {
        value: 'sudanese',text: 'Sudanese'
      },
      {
        value: 'surinamer',text: 'Surinamer'
      },
      {
        value: 'swazi',text: 'Swazi'
      },
      {
        value: 'swedish',text: 'Swedish'
      },
      {
        value: 'swiss',text: 'Swiss'
      },
      {
        value: 'syrian',text: 'Syrian'
      },
      {
        value: 'taiwanese',text: 'Taiwanese'
      },
      {
        value: 'tajik',text: 'Tajik'
      },
      {
        value: 'tanzanian',text: 'Tanzanian'
      },
      {
        value: 'thai',text: 'Thai'
      },
      {
        value: 'togolese',text: 'Togolese'
      },
      {
        value: 'tongan',text: 'Tongan'
      },
      {
        value: 'trinidadian or tobagonian',text: 'Trinidadian or Tobagonian'
      },
      {
        value: 'tunisian',text: 'Tunisian'
      },
      {
        value: 'turkish',text: 'Turkish'
      },
      {
        value: 'tuvaluan',text: 'Tuvaluan'
      },
      {
        value: 'ugandan',text: 'Ugandan'
      },
      {
        value: 'ukrainian',text: 'Ukrainian'
      },
      {
        value: 'uruguayan',text: 'Uruguayan'
      },
      {
        value: 'uzbekistani',text: 'Uzbekistani'
      },
      {
        value: 'venezuelan',text: 'Venezuelan'
      },
      {
        value: 'vietnamese',text: 'Vietnamese'
      },
      {
        value: 'welsh',text: 'Welsh'
      },
      {
        value: 'yemenite',text: 'Yemenite'
      },
      {
        value: 'zambian',text: 'Zambian'
      },
      {
        value: 'zimbabwean',text: 'Zimbabwean'
      },
    ]
}
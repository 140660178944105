<template>
  <v-content>
    <div
      style="overflow: hidden;height: 100vh;background: linear-gradient(90deg, rgba(254,49,96,1) 0%, rgba(241,30,43,1) 100%);background-color: rgb(254,49,96);"
    >
      <v-row
        style="height: 100%;background-size: contain;background-repeat: no-repeat; background-position: top center;"
        :style="{'background-image': `url(${publicPath}img/share.jpg)`}"
      >
        <v-col style="height: 100%" @click="share('facebook')"></v-col>
        <v-col style="height: 100%" @click="share('line')"></v-col>
      </v-row>
    </div>
  </v-content>
</template>

<script>
export default {
  data() {
    return {
      link: null,
      appid: null,
      shareAble: false,
      publicPath: process.env.BASE_URL
    };
  },
  mounted() {
    /* eslint-disable */
    if (liff.isLoggedIn()) {
      this.shareAble = true;
    } else {
      liff.login({
        redirectUri: window.location.href
      });
    }
    /* eslint-enable */

    if (this.$route.query.link) {
      this.link = "https://" + this.$route.query.link;
    }
  },
  methods: {
    async share(type) {
      if (type == "facebook") {
        window.open(
          "fb-messenger://share?link=" + encodeURIComponent(this.link)
        );
      } else if (type == "line") {
        //
        if (this.shareAble) {
          // eslint-disable-next-line
          await liff.shareTargetPicker([
            {
              type: "text",
              text: this.link
            }
          ]);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
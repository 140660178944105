<template>
  <div class="caption grey--text mb-2">
    <span class="red--text mr-1" v-if="typeof isRequired !== 'undefined'">*</span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ["isRequired"]
};
</script>

<style lang="scss" scoped>
</style>
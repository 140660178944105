<template>
  <ValidationObserver ref="observer" v-slot="{}" tag="form">
    <v-card>
      <v-card-title class="primary white--text">
        <v-row no-gutters>
          <v-col>Language</v-col>
          <v-col class="text-right">
            <v-btn text fab small dark @click="$emit('onClose')">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <div class="mb-3 mt-3">
          <v-row>
            <v-col cols="12" sm="4">
              <form-label isRequired>Language / ภาษา</form-label>
              <ValidationProvider
                :name="'Language / ภาษา'"
                :rules="'required'"
                v-slot="{ errors, valid }"
              >
                <v-text-field
                  label="Language / ภาษา"
                  solo
                  persistent-hint
                  v-model="language.lang"
                  :error-messages="errors"
                  :success="valid"
                  :hide-details="errors.length == 0"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="4">
              <form-label>Test Type / ประเภทแบบทดสอบ</form-label>
              <ValidationProvider :name="'Test Type / ประเภทแบบทดสอบ'" v-slot="{ errors, valid }">
                <v-text-field
                  label="Test Type / ประเภทแบบทดสอบ"
                  solo
                  persistent-hint
                  v-model="language.type"
                  :error-messages="errors"
                  :success="valid"
                  :hide-details="errors.length == 0"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="4">
              <form-label>Score / คะแนน</form-label>
              <ValidationProvider
                :rules="'min_value:0|max_value:999'"
                :name="'Score / คะแนน'"
                v-slot="{ errors, valid }"
              >
                <v-text-field
                  label="Score / คะแนน"
                  solo
                  persistent-hint
                  v-model="language.score"
                  :error-messages="errors"
                  :success="valid"
                  :hide-details="errors.length == 0"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <div class="text-center">
            <v-btn depressed class="ma-1" @click="$emit('onClose')">Cancel</v-btn>
            <v-btn depressed class="primary ma-1" @click="onSave">Save</v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </ValidationObserver>
</template>

<script>
import FormLabel from "@/components/FormLabel";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  components: {
    FormLabel,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      language: {
        lang: null,
        type: null,
        score: null,
      },
    };
  },
  methods: {
    async onSave() {
      var isValid = await this.$refs.observer.validate();

      if (!isValid) {
        this.$nextTick(() => {
          this.$vuetify.goTo(".error--text", {
            duration: 300,
            offset: 0,
            easing: "easeInOutCubic",
            container: ".v-dialog",
          });
        });
        return false;
      }

      this.$emit("onSave", this.language);
    },
  },
};
</script>

<style lang="scss" scoped></style>

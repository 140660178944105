<template>
  <div
    style="max-height: 70vh;border-radius: 10px; overflow: auto; border: 1px solid #eee; padding: 30px 20px;    word-break: break-word;"
  >
    <div class="text-center font-weight-bold">
      เกี่ยวกับความเป็นส่วนตัวของข้อมูล <br />
      Privacy Notice
    </div>

    <p>
      เว็บไซต์ career.scg.com นี้ เป็นเว็บไซต์สำหรับการสมัครงาน สมัครฝึกงานของเอสซีจี
      (บริษัทปูนซิเมนต์ไทย จำกัด (มหาชน) และบริษัทในกลุ่มเอสซีจี)
      ข้อมูลที่ผู้สมัครให้ไว้ในเว็บไซต์นี้เพื่อใช้สมัครงานกับบริษัทปูนซิเมนต์ไทย จำกัด (มหาชน)
      และบริษัทในกลุ่มเอสซีจี โดยเว็บไซต์จะส่งตำแหน่งงานที่เหมาะสมให้กับผู้สมัคร
      หรือผู้สมัครสามารถเลือกตำแหน่งงานที่ต้องการได้เอง โปรดอ่านข้อกำหนดและเงื่อนไข
      นโยบายความเป็นส่วนตัว นโยบายการใช้คุกกี้ ได้ที่
      <a
        href="https://www.scg.com/th/09legal_privacy/privacy-notice-job-appliants.html"
        target="_blank"
        >https://www.scg.com/th/09legal_privacy/privacy-notice-job-appliants.html</a
      >
    </p>

    <p>
      This website (career.scg.com) is intended for recruitment by and internship at SCG (the Siam
      Cement PLC and affiliates) Personal Data of applicants will be processed for the recruitment
      and internship purposes by all companies in SCG. The website will inform the applicants of job
      vacancies or they can choose to apply for available positions they are interested in. Our
      terms and conditions, privacy notice, and cookies policy are available at
      <a
        href="https://www.scg.com/en/09legal_privacy/privacy-notice-job-appliants.html"
        target="_blank"
        >https://www.scg.com/en/09legal_privacy/privacy-notice-job-appliants.html</a
      >
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
p {
  text-indent: 5%;
}
</style>

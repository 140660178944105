<template>
  <ValidationObserver ref="observer" v-slot="{}" tag="form">
    <v-card>
      <v-card-title class="primary white--text">
        <v-row no-gutters>
          <v-col>Other Skill</v-col>
          <v-col class="text-right">
            <v-btn text fab small dark @click="$emit('onClose')">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <div class="mb-3 mt-3">
          <v-row>
            <v-col cols="12" sm="6">
              <form-label isRequired>Skill Category / ประเภททักษะ</form-label>
              <ValidationProvider
                :name="'Skill Category / ประเภททักษะ'"
                :rules="'required'"
                v-slot="{ errors, valid }"
              >
                <v-text-field
                  label="Skill Category / ประเภททักษะ"
                  solo
                  persistent-hint
                  v-model="otherskill.category"
                  :error-messages="errors"
                  :success="valid"
                  :hide-details="errors.length == 0"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6">
              <form-label>Skill / ทักษะ</form-label>
              <ValidationProvider :name="'Skill / ทักษะ'" v-slot="{ errors, valid }">
                <v-text-field
                  label="Skill / ทักษะ"
                  solo
                  persistent-hint
                  v-model="otherskill.skill"
                  :error-messages="errors"
                  :success="valid"
                  :hide-details="errors.length == 0"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12">
              <form-label>Level / ระดับความชำนาญ</form-label>
              <div class="mt-10">
                <v-slider
                  v-model="otherskill.level"
                  step="1"
                  max="10"
                  min="1"
                  thumb-label="always"
                  color="primary"
                ></v-slider>
              </div>
              <!-- <v-rating v-model="otherskill.level" :length="10" color="primary"></v-rating> -->
            </v-col>
          </v-row>
          <div class="text-center">
            <v-btn depressed class="ma-1" @click="$emit('onClose')">Cancel</v-btn>
            <v-btn depressed class="primary ma-1" @click="onSave">Save</v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </ValidationObserver>
</template>

<script>
import FormLabel from "@/components/FormLabel";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  components: {
    FormLabel,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      otherskill: {
        name: null,
        type: null,
        level: null
      }
    };
  },
  methods: {
    async onSave() {
      var isValid = await this.$refs.observer.validate();

      if (!isValid) {
        this.$nextTick(() => {
          this.$vuetify.goTo(".error--text", {
            duration: 300,
            offset: 0,
            easing: "easeInOutCubic",
            container: ".v-dialog"
          });
        });
        return false;
      }

      this.$emit("onSave", this.otherskill);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <div>
    <template v-if="currentImage">
      <div
        class="text-xs-center"
        style="position: relative; overflow: hidden; background: #eee; border-radius: 5px"
      >
        <img
          :src="currentImage"
          alt="Upload Product Image"
          style="height: 100%; margin-bottom: -10px"
          :style="{ height: height + 'px' }"
        />
        <div
          style="
            background: #00000036;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
          "
          :style="{ opacity: showUpload ? 1 : 0 }"
          @mouseover="showUpload = true"
          @mouseleave="showUpload = false"
        >
          <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0">
            <v-layout justify-center align-center style="height: 100%">
              <v-flex>
                <v-btn dark color="primary" @click="onClickUploadFile()" x-small>{{
                  $t("Upload")
                }}</v-btn>
                <br />
                <span class="caption">Upload file size: 5MB</span>
              </v-flex>
            </v-layout>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        style="height: 250px; background: #eee; overflow: hidden; position: relative"
        :style="{ height: height + 'px' }"
      >
        <template v-if="loading">
          <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0">
            <v-layout justify-center align-center style="height: 100%">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-layout>
          </div>
        </template>
        <template v-else>
          <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0">
            <v-layout justify-center align-center style="height: 100%">
              <v-flex>
                <v-btn dark color="primary" @click="onClickUploadFile()" x-small>{{
                  $t("Upload")
                }}</v-btn>
                <br />
                <span class="caption">Upload file size: 5MB</span>
              </v-flex>
            </v-layout>
          </div>
        </template>
      </div>
    </template>

    <ValidationObserver ref="observer" v-slot="{}" tag="form">
      <ValidationProvider :name="'profile'" :rules="'image|size:5120'" v-slot="{ errors, valid }">
        <input
          type="file"
          v-show="false"
          ref="inputUpload"
          class="form-control-file pt-2"
          name="photoUpload"
          id="photoUploadInput"
          data-vv-as="File"
          :key="`upload_${uploadtimestamp}`"
          @change="onFileChange($event)"
          style="
            overflow: hidden;
            position: fixed;
            width: 1px;
            height: 1px;
            z-index: -1;
            opacity: 0;
          "
        />
      </ValidationProvider>
    </ValidationObserver>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  props: {
    value: null,
    height: {
      default: 100,
      type: Number,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: function () {
    return {
      showUpload: false,
      currentImage: this.value,
      isAlert: false,
      loading: false,
      uploadtimestamp: moment().format("x"),
    };
  },
  methods: {
    onClickUploadFile() {
      var vm = this;
      vm.uploadtimestamp = moment().format("x");
      vm.$nextTick(() => {
        vm.$refs.inputUpload.click();
      });
    },
    onClickUploadBtn() {
      document.getElementById("photoUploadInput").click();
    },
    fileResize: function (file, callback) {
      let vm = this;
      var reader = new FileReader();
      reader.onloadend = function () {
        var tempImg = new Image();
        tempImg.src = reader.result;
        tempImg.onload = function () {
          var MAX_WIDTH = 1000;
          var MAX_HEIGHT = 1000;
          var tempW = tempImg.width;
          var tempH = tempImg.height;
          if (tempW > tempH) {
            if (tempW > MAX_WIDTH) {
              tempH *= MAX_WIDTH / tempW;
              tempW = MAX_WIDTH;
            }
          } else {
            if (tempH > MAX_HEIGHT) {
              tempW *= MAX_HEIGHT / tempH;
              tempH = MAX_HEIGHT;
            }
          }

          var canvas = document.createElement("canvas");
          canvas.width = tempW;
          canvas.height = tempH;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(this, 0, 0, tempW, tempH);
          var dataURL = canvas.toDataURL("image/jpeg");
          var data = "image=" + dataURL;
          var obj = vm.dataURItoBlob(data);
          obj.name = file.name;
          callback(obj);
        };
      };
      reader.readAsDataURL(file);
    },
    dataURItoBlob: function (dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0) byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], { type: mimeString });
    },
    onFileChange: async function (event) {
      this.isAlert = false;
      let vm = this;
      var isValid = await this.$refs.observer.validate();

      if (!isValid) {
        vm.uploadtimestamp = moment().format("x");
        return false;
      }
      let files = event.target.files || event.dataTransfer.files;
      if (!files.length) {
        vm.uploadtimestamp = moment().format("x");
        return;
      }
      // vm.fileResize(files[0], function(obj) {});
      vm.createImage(files[0]);
      event.target.value = "";
    },
    createImage(file) {
      // let reader = new FileReader();
      let vm = this;
      vm.upload(file);
    },
    upload(file) {
      let data = {
        name: file.name,
        type: file.type,
        file: file,
      };
      let vm = this;
      vm.loading = true;

      let url =
        "https://api-enterprise.zwiz.app/" +
        "sign-s3?file-name=" +
        data.name +
        "&file-type=" +
        data.type;

      axios
        .get(url)
        .then((response) => {
          // JSON responses are automatically parsed.
          response = response.data;
          response.file = data.file;
          this.uploadfile(response, function () {
            vm.currentImage = response.url;
            vm.loading = false;
            vm.uploadtimestamp = moment().format("x");
            vm.$emit("input", vm.currentImage);
          });
        })
        .catch(() => {
          vm.loading = false;
          vm.isAlert = true;
        });
    },
    uploadfile: function (data, callback) {
      let signedRequest = data.signedRequest;
      let request = data.file;
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", signedRequest);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            callback();
          } else {
            alert("Could not upload file.");
          }
        }
      };
      xhr.send(request);
    },
  },
  watch: {
    value: function (newVal) {
      this.currentImage = newVal;
    },
  },
};
</script>
